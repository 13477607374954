import React from 'react';
import Link from '@material-ui/core/Link/index';

export const onboardingSlice = set => ({
  completedStep: null,
  onboardingMinimized: false,
  activeStepIndex: null,
  adsTxtDownloaded: null,
  onboardingSteps: {},
  onboardingStepsByType: {
    managed: {
      1: {
        name: `Ads.txt`,
        description: `Add the following lines to your site's ads.txt file so we can validate your domain and start receiving bids from demand partners.`,
        buttonText: `Go to Ads.txt`,
        buttonTextActiveLink: `Place ads.txt file to continue`,
        link: `/ads-txt`,
        // tooltipText: 'First, download and implement the Ads.txt file.',
      },
      2: {
        name: `Multiple Customer Management`,
        description: `Fill out the required information about your Google account so we can enable Google AdX demand.`,
        buttonText: `Enter information`,
        buttonTextActiveLink: `Fill in MCM form to continue`,
        link: `/mcm`,
        // tooltipText: '✍️ Fill in required Google information',
        enabledIf: 'MCM_enabled',
      },
      3: {
        name: `Billing`,
        description: `Fill out the required legal information, so we can issue invoices and make payments.`,
        buttonText: `Go to Billing`,
        buttonTextActiveLink: `Enter billing details to continue`,
        link: `/billing`,
        //tooltipText: 'Choose how you get paid',
        enabledIf: 'billingEnabled',
        skippable: true,
      },
      4: {
        name: `Chrome Extension`,
        description: `Install Chrome extension to detect Setupad monetization and report unwanted ads.`,
        buttonText: `Download extension`,
        buttonTextActiveLink: `Download extension to continue`,
        link: `/blocked-ads`,
        //tooltipText: 'Download Setupad Google Chrome extension',
        skippable: true,
      },
      5: {
        name: `Google Analytics`,
        description: `Log in with Google to assign Google Analytics property to your domain and improve monetization.`,
        buttonText: `Configure`,
        buttonTextActiveLink: `Assign Analytics property to continue`,
        link: `/google-analytics`,
        tooltipText: 'Connect your Google Analytics account to get more insights',
        enabledIf: 'google_analytics_enabled',
        skippable: true,
      },
      6: {
        name: `Secure your account`,
        description: `Change your password to strengthen your account.`,
        buttonText: `Change password`,
        buttonTextActiveLink: `Request reset link to continue`,
        // tooltipText: 'Change your password',
        link: `/change-password`,
        skippable: true,
      },
      7: {
        name: `Test recommendations`,
        description: `Read how to make objective comparisons and evaluate Setupad result.`,
        buttonText: `Read test recommendations`,
        link: `https://client.setupad.com/Setupad_HB_test_2022.pdf`,
        //tooltipText: '📘 Read test recommendations',
        externalLink: true,
        skippable: true,
      },
      8: {
        name: `Onboarding completed! ✨`,
        description: `If you have any questions or need help, please contact your Account Executive!`,
        buttonText: `Close`,
      },
    },
    selfserve: {
      1: {
        name: `Connect Google account`,
        description: `Follow the onboarding steps, and the automatic 40-minute initialization process will prepare GAM to host Prebid connections seamlessly. `,
        buttonText: `Setup Google Ad Manager`,
        buttonTextActiveLink: `Configure Ad Manager to continue`,
        link: `/ad-manager`,
        // skippable: true,
      },
      2: {
        name: `Ads.txt redirect`,
        description: `We highly recommend using Setupad's ads.txt redirect. Set it up once and any changes you make will be automatically included in the redirected ads.txt file. Edit the ads.txt redirect by clicking edit ads.txt.`,
        buttonText: `Go to Ads.txt`,
        buttonTextActiveLink: `Implement redirect to continue`,
        link: `/ads-txt`,
        skippable: true,
      },
      3: {
        name: `Consent Management Platform check`,
        description: `Check the presence of an active CMP on your domains with our CMP tracker service. We recommend using Setupad CMP to secure effective monetization and comply with all legal requirements.`,
        buttonText: `Go to CMP page`,
        buttonTextActiveLink: `Implement Setupad CMP to continue`,
        link: `/cmp`,
        skippable: true,
      },
      4: {
        name: `Subscription setup`,
        description: `Overview your subscription plan and add a credit card to continue.`,
        buttonText: `Go to subscription page`,
        buttonTextActiveLink: `Add Credit Card to continue`,
        link: `/subscription`,
        skippable: true,
      },
      5: {
        name: `Prebid Panel setup`,
        description: (
          <>
            You are all set to start using the main self-serve functionality for your everyday tasks
            on PrebidPanel. Learn how to use it by continuing to follow the pop-up notifications.
            Get more descriptive guidance in the{' '}
            {
              <Link href="/knowledge-base" color="primary">
                Knowledge base
              </Link>
            }{' '}
            section.
          </>
        ),
        buttonText: `Go to Prebid Panel`,
        buttonTextActiveLink: ``,
        link: `/prebid-panel`,
        //  skippable: true,
      },
    },
    bidder: {
      1: {
        name: `Update Ads.txt`,
        description: `Add Setupad’s reseller ID lines to your ads.txt file. For easier management and automation, we recommend using the Setupad redirect solution for ads.txt updates.`,
        buttonText: `Go to Ads.txt`,
        buttonTextActiveLink: `Implement redirect to continue`,
        link: `/ads-txt`,
        skippable: true,
      },
      2: {
        name: `Configure Prebid Settings`,
        description: `Add Setupad account and placement ID parameters to your Prebid setup. Split desktop and mobile traffic for better results.`,
        buttonText: `View your inventory`,
        link: `/inventory`,
        skippable: true,
      },
      3: {
        name: `Check and Update Your CMP`,
        description: (
          <>
            Ensure your CMP includes Setupad and its reseller SSPs for consent collection.
            <br /> <br />
            If Setupad and its partners are not listed, resurface the CMP pop-up to all users after
            implementation. This will speed up the process of collecting consent.
            <br /> <br />
            For better consent collection, consider implementing Setupad’s CMP solution
          </>
        ),
        buttonText: `Go to CMP page`,
        buttonTextActiveLink: `Implement Setupad CMP to continue`,
        link: `/cmp`,
        skippable: true,
      },
      4: {
        name: `Set Up an Advertiser Blocklist`,
        description: `Use the Blocklist section to add domain and category blocks, ensuring bids from these advertisers are avoided.`,
        buttonText: `Create Blocklist`,
        buttonTextActiveLink: `Submit Blocklist to continue`,
        link: `/block-list`,
        skippable: true,
      },
      5: {
        name: `Review Performance`,
        description: `Observe the performance statistics the next day after the implementation!`,
        buttonText: `Go to Reporting`,

        link: `/home`,
      },
    },
  },
  onboardingTypeByAccountType: {
    0: 'bidder',
    1: 'managed',
    4: 'managed',
    5: 'selfserve',
    6: 'selfserve',
    7: 'selfserve',
  },
  onboardingExtras: {
    title: {
      managed: 'Welcome to Setupad Client UI! 👋',
      selfserve: 'Welcome to Setupad Self-Serve! 👋',
      bidder: 'Welcome to Setupad Bidder! 👋',
    },
    description: {
      managed: `This is an onboarding guide designed to help you get started. Please follow these steps and start monetizing your website successfully!`,
      selfserve: `We're thrilled to have you on board! We've put together this guide to make your onboarding with us as smooth as possible. Let's get started!`,
      bidder: `We're thrilled to have you on board! We've put together this guide to make your onboarding with us as smooth as possible. Let's get started!`,
    },
  },
});
