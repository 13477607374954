import React from 'react';
import {makeStyles} from '@material-ui/core/styles/index';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Chip from '@material-ui/core/Chip';
import {Link} from 'react-router-dom';
import {PlayCircleOutline as PlayIcon} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  mainWrapper: {
    marginTop: theme.spacing(2),
  },
  wrapper: {
    margin: theme.spacing(5),
  },
  media: {
    height: 167,
  },
  mediaSmall: {
    height: 167,
    borderRadius: 20,
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 0px 6px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
    backgroundPosition: `center !important`,
    backgroundSize: `cover !important`,
    transition: 'background-position 0.5s ease-in-out, box-shadow 0.2s ease-in-out',
  },
  card: {
    marginBottom: theme.spacing(2),
    background: 'none!important',
    '&:hover': {
      '&:hover $mediaSmall': {
        backgroundPosition: `top left!important`,
        boxShadow: 'inset rgba(0, 0, 0, 0.7) 0px 1px 4px, rgba(0, 0, 0, 0.4) 0px 1px 2px',
      },
      '&:hover $cardContent': {
        //  top: -15,
      },
    },
  },
  cardContent: {
    transition: 'top 0.5s ease-in-out',
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
    background: theme.palette.background.paper,
    fontWeight: `700!important`,
    zIndex: 2,
    margin: '0 12px',
    top: -30,
    position: 'relative',
    padding: 15,
    borderRadius: 20,
  },
}));

// Different regex, because it matches special symbols and removes them.
// [^ negated set, matches something that is not a-z A-Z 0-9]
function slugify(str, exceptions = []) {
  let matchSpecialSymbolsPart1 = '[^a-zA-Z0-9';
  exceptions.forEach(exception => (matchSpecialSymbolsPart1 += `\\${exception}`));
  const mathSpecialSymbolsPart2 = ']+';
  matchSpecialSymbolsPart1 += mathSpecialSymbolsPart2;
  const matchSpecialSymbols = new RegExp(matchSpecialSymbolsPart1, 'g');
  return str
    .replace(matchSpecialSymbols, '')
    .split(' ')
    .join('-')
    .toLowerCase();
}

const ArticleCard = ({title, id, category, time, image}) => {
  const classes = useStyles();

  return (
    <>
      <Grid
        item
        xs={3}
        className={classes.article}
        component={Link}
        to={`/article/${id}/${slugify(title, [' '])}`}
        style={{textDecoration: 'none'}}
      >
        <Card elevation={0} className={classes.card}>
          <CardActionArea
            styles={{
              focusHighlight: provided => ({
                ...provided,
                opacity: 0,
                '&:hover': {
                  backgroundColor: 'red',
                },
              }),
            }}
          >
            <CardMedia className={classes.mediaSmall} image={image} title={title} />

            <CardContent className={classes.cardContent}>
              {category && (
                <Chip
                  icon={category === 'Video' ? <PlayIcon /> : ''}
                  label={category}
                  color="primary"
                  size="small"
                />
              )}

              <Typography
                gutterBottom
                variant="caption"
                component="h2"
                color={'textSecondary'}
              ></Typography>
              <Typography
                gutterBottom
                variant="body2"
                component="h2"
                style={{fontWeight: 600, textDecoration: 'none!important'}}
                color={'textPrimary'}
              >
                <div dangerouslySetInnerHTML={{__html: title}} />
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </>
  );
};

export default ArticleCard;
