import React, {useState, useCallback, useEffect} from 'react';
import {Grid, Container, Typography, CircularProgress} from '@material-ui/core';
import FadeIn from 'react-fade-in/lib/FadeIn';
import Placeholder from './../misc/Placeholder';
import useStore from '../../store/store';
import useSnackbarSuccessOrError from './subscription/customerSuccessBar';
import {PeriodPicker} from './PeriodPicker';
import {APICall} from '../../api/api';
import StackedDemandGraph from './stackedDemand/StackedDemandGraph';

const StackedDemand = ({isMobile, login}) => {
  const [data, setData] = useState();
  const selection = useStore(state => state.selection);
  const period = useStore(state => state.period);
  const abortController = new AbortController();
  const [callSnackbarSuccessError] = useSnackbarSuccessOrError();

  // Fetches realtime raw data from API
  const fetchData = useCallback(async () => {
    try {
      // Reset raw and structured data
      setData(null);

      let data = await APICall({
        endpoint: 'totalclientstacked',
        params: `${period.startDate}/${period.endDate}${
          selection.value.publisher ? `/${selection.value.publisher}` : ``
        }${selection.value.domain ? `/${selection.value.domain}` : ``}${
          selection.value.placement ? `/${selection.value.placement}` : ``
        }`,
        options: {
          signal: abortController.signal,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${login.reportingToken}`,
            'Cache-Control': 'max-age=0, no-cache',
            'Content-Type': 'application/json',
          },
        },
      });

      setData(data);
    } catch ({message}) {
      callSnackbarSuccessError(false, '', message);
      setData({
        success: false,
        error: message,
      });
    }
  }, [login, setData, selection, period, abortController]);

  useEffect(() => {
    fetchData();
  }, [login, selection, period]);

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={'h6'}>Stacked demand report</Typography>
        </Grid>
        <PeriodPicker isMobile={isMobile} />

        {!data && (
          <Grid item xs={12}>
            <div style={{textAlign: 'center'}}>
              <FadeIn>
                <Typography variant={'caption'} display="block" gutterBottom>
                  Loading data
                </Typography>
                <CircularProgress />
              </FadeIn>
            </div>
          </Grid>
        )}

        {data?.length === 0 && !data?.error && (
          <Grid item xs={12}>
            <Container
              maxWidth="xl"
              style={{
                padding: 65,
                textAlign: 'center',
              }}
            >
              <Placeholder />
              <Typography align={'center'}>No data</Typography>
            </Container>
          </Grid>
        )}

        {data?.length > 0 && !data?.error && (
          <Grid item xs={12}>
            <FadeIn>
              <StackedDemandGraph data={data} isMobile={isMobile} />
            </FadeIn>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default StackedDemand;
