import React, {useEffect, useRef} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import {APICall} from '../../api/api';
import DecilesTable from './DecilesTable';
import CMPvisualiser from './CMPvisualiser';
import Grid from '@material-ui/core/Grid';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import Select from '@material-ui/core/Select';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import {makeStyles, useTheme, withStyles} from '@material-ui/core/styles/index';
import millify from 'millify';
import Link from '@material-ui/core/Link/index';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Switch} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import FadeIn from 'react-fade-in';
import ReactGA from 'react-ga4';

import {useMediaQuery} from 'react-responsive';
import breakpoints from './../helpers/breakpoints';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import useStore from '../../store/store';
import {LockedFeature} from './LockedFeature';
import {PeriodPicker} from './PeriodPicker';

const useStyles = makeStyles(theme => ({
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: '149px',
    textAlign: 'left',
    //float: 'left',
    display: 'inline-block',
    marginTop: 6,
    marginLeft: 15,
  },
  buttons: {
    margin: theme.spacing(2),
  },
  sorted: {
    background: theme.palette.graphColors.sorted,
  },
}));

const sspDomains = {
  ix: 'https://www.indexexchange.com/',
  indexexchange: 'https://www.indexexchange.com/',
  ixS2S: 'https://www.indexexchange.com/',
  rubicon: 'https://rubiconproject.com/',
  rubiconS2S: 'https://rubiconproject.com/',
  smartadserver: 'https://smartadserver.com/',
  smartadserverS2S: 'https://smartadserver.com/',
  adform: 'https://site.adform.com/',
  adformS2S: 'https://site.adform.com/',
  appnexus: 'https://www.appnexus.com',
  appnexusS2S: 'https://www.appnexus.com',
  appnexusAst: 'https://www.appnexus.com',
  criteo: 'https://www.criteo.com/',
  criteoS2S: 'https://www.criteo.com/',
  pulsepoint: 'https://pulsepoint.com/',
  pulsepointS2S: 'https://pulsepoint.com/',
  rtbhouse: 'https://www.rtbhouse.com/',
  rtbhouseS2S: 'https://www.rtbhouse.com/',
  pubmatic: 'https://pubmatic.com/',
  pubmaticS2S: 'https://pubmatic.com/',
  adagio: 'https://adagio.io/',
  adagioS2S: 'https://adagio.io/',
  '33across': 'https://www.33across.co.uk/',
  '33acrossS2S': 'https://www.33across.co.uk/',
  openx: 'https://www.openx.com/',
  openxS2S: 'https://www.openx.com/',
  connectad: 'https://connectad.ca/',
  connectadS2S: 'https://connectad.ca/',
  districtm: 'https://www.districtm.net/',
  districtmS2S: 'https://www.districtm.net/',
  districtmdmx: 'https://www.districtm.net/',
  improvedigital: 'https://www.improvedigital.com/',
  improvedigitalS2S: 'https://www.improvedigital.com/',
  sovrn: 'https://www.sovrn.com/',
  sovrnS2S: 'https://www.sovrn.com/',
  decenterads: 'https://decenterads.com/',
  decenteradsS2S: 'https://decenterads.com/',
  emxdigital: 'https://emxdigital.com/',
  brealtime: 'https://emxdigital.com/',
  fidelity: 'https://fidelity-media.com/',
  fidelityS2S: 'https://fidelity-media.com/',
  Lrytas: 'https://www.lrytas.lt/',
  Luponmedia: 'https://luponmedia.com/',
  'Media bitės': 'https://www.mediabites.lt/',
  Etaplius: 'https://www.etaplius.lt/',
  eskimi: 'https://eskimi.com/',
  conversant: 'https://www.conversantmedia.eu/',
  epsilon: 'https://www.epsilon.com',
  Kasvyksta: 'https://kasvyksta.lt/',
  luponmedia: 'https://luponmedia.com/',
  etaplius: 'https://www.etaplius.lt/',
  adyoulike: 'https://www.adyoulike.com/',
  nobid: 'https://www.nobid.io/',
  amx: 'https://adaptmx.com/',
  rise: 'https://cloudfront.risecodes.com/',
  medianet: 'https://www.media.net/',
  vidazoo: 'https://perion.com/',
  triplelift: 'https://triplelift.com/',
  livewrapped: 'https://www.livewrapped.com/',
  sharethrough: 'https://www.sharethrough.com/',
  nextMillennium: 'https://www.nextmillennium.com/',
  onetag: 'https://www.onetag.com/',
  oftmedia: 'https://152media.com/',
  holid: 'https://holid.io/',
  admatic: 'https://admatic.com/',
  bcmssp: 'https://www.brightcom.com/',
  bidinfluence: 'https://bidinfluence.com/',
};

const getFavIconForSSP = ssp => {
  if (ssp === 'Google') {
    return (
      <img
        src={`https://www.gstatic.com/admanager/ad_manager.ico`}
        style={{position: 'relative', top: 3, marginRight: 6, height: 16}}
      />
    );
  } else if (ssp === 'Amazon') {
    return (
      <img
        src={`https://advertising.amazon.com/favicon.ico`}
        style={{position: 'relative', top: 3, marginRight: 6, height: 16}}
      />
    );
  } else if (ssp === 'setupad') {
    return (
      <img
        src={`https://setupad.com/wp-content/uploads/2020/04/favicon-icon-192.png`}
        style={{position: 'relative', top: 3, marginRight: 6, height: 16}}
      />
    );
  } else if (ssp === 'between') {
    return (
      <img
        src={`https://client.setupad.com/static/bx-logo.png`}
        style={{position: 'relative', top: 3, marginRight: 6, height: 16}}
      />
    );
  } else if (ssp === 'AMD LT') {
    return (
      <img
        src={`https://broadbandtvnews-broadbandtvnews.netdna-ssl.com/wp-content/uploads/2018/08/All-Media-Baltics.png`}
        style={{position: 'relative', top: 3, marginRight: 6, height: 16}}
      />
    );
  } else if (ssp === 'AMD LT (Video)') {
    return (
      <img
        src={`https://broadbandtvnews-broadbandtvnews.netdna-ssl.com/wp-content/uploads/2018/08/All-Media-Baltics.png`}
        style={{position: 'relative', top: 3, marginRight: 6, height: 16}}
      />
    );
  } else if (sspDomains[ssp]) {
    return (
      <img
        src={`https://www.google.com/s2/favicons?domain=${sspDomains[ssp]}`}
        style={{position: 'relative', top: 3, marginRight: 6}}
      />
    );
  } else {
    // Placeholder
    return (
      <div
        style={{
          display: 'inline-block',
          position: 'relative',
          top: 3,
          marginRight: 6,
          height: 16,
          width: 16,
        }}
      />
    );
  }
};

const StyledTableCell = withStyles(theme => ({
  head: {
    background: theme.palette.graphColors.themeDarkGray,
    borderBottom: `1px solid ${theme.palette.graphColors.themeDarkGray}`,
  },
  body: {
    borderBottom: `1px solid ${theme.palette.graphColors.themeDarkGray}`,
  },
}))(TableCell);

const DecilesView = React.memo(props => {
  const isMobile = useMediaQuery({query: breakpoints.mobile});
  const theme = useTheme();
  const classes = useStyles();
  const [decilesData, setDecilesData] = React.useState(false);
  const [intervalsMode, setInvervalsMode] = React.useState(0); // 1 for fixed, 0 for deciles
  const [decilesDataWithoutPassbacks, setDecilesDataWithoutPassbacks] = React.useState(false);
  const [googleData, setGoogleData] = React.useState(false);
  const [amazonData, setAmazonData] = React.useState(false);
  const [setupadData, setSetupadData] = React.useState(false);
  const [setupadPMP, setSetupadPMP] = React.useState(false);
  const [setupadOB, setSetupadOB] = React.useState(false);
  const [propelMedia, setPropelMedia] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [bidders, setBidders] = React.useState(null);
  const [selectedBidder, setSelectedBidder] = React.useState('Combined data');
  const [error, setError] = React.useState(false);
  const [calculatedData, setCalculatedData] = React.useState(false);
  const [calculatedPieData, setCalculatedPieData] = React.useState(false);
  const abortController = new AbortController();
  const chartRef = useRef();
  const [mobileViewAnchor, setMobileViewAnchor] = React.useState(null);
  const calculated = useStore(state => state.calculated);

  const selection = useStore(state => state.selection);
  const period = useStore(state => state.period);

  const [sort, setSort] = React.useState({key: 'revShare', direction: 'down'});

  const renamesConfig = {
    adform_publisher: {
      zmoneslt: 'Media bitės',
      AMDigital_lt: 'AMD LT',
      etapliuslt: 'Etaplius',
    },
    adform_outstream_publisher: {
      AMDigital_lt: 'AMD LT (Video)',
    },
  };

  const getSSPname = original => {
    let replacement = renamesConfig[original]?.[props.login.username];

    original = replacement ? replacement : original;

    return original?.replace('S2S', ' S2S');
  };

  useEffect(() => {
    setLoading(true);
    setDecilesData(false);
    setDecilesDataWithoutPassbacks(false);
    if (calculated) {
      getDecilesData();
    }

    return () => abortController.abort();
  }, [selection.value, period, calculated]);

  useEffect(() => {
    if (googleData && decilesData) {
      calculateDecilesDataForGraph(decilesData);
    }
  }, [intervalsMode]);

  const getDecilesData = async () => {
    let data = await APICall({
      endpoint: 'getDecilesDataBySSP',
      options: {
        method: 'POST',
        body: JSON.stringify({
          selection: selection,
          period: period,
        }),
        headers: {
          Authorization: `Bearer ${props.login.token}`,
          'Cache-Control': 'max-age=0, no-cache',
          'Content-Type': 'application/json',
        },
      },
    });

    setLoading(false);
    if (data.success) {
      setError(null);

      // Filter Google data to get only hb_bidder details
      let filteredDataForSSPsWithoutPassbacks = data.data.filter(
        row =>
          row.Dimension.CUSTOM_CRITERIA.includes('_bidder') &&
          !row.Dimension.LINE_ITEM_NAME.includes('passback')
      );
      let filteredDataForSSPs = data.data.filter(row =>
        row.Dimension.CUSTOM_CRITERIA.includes('_bidder')
      );

      setDecilesData(filteredDataForSSPs);
      setDecilesDataWithoutPassbacks(filteredDataForSSPsWithoutPassbacks);
      setGoogleData(data.googleData);
      setAmazonData(data.amazonData);
      setSetupadData(data.setupadData);
      setSetupadPMP(data.setupadPMP);
      setSetupadOB(data.setupadOB);
      setPropelMedia(data.propelMedia);

      //console.log('filteredDataForSSPs',filteredDataForSSPs)
      calculateDecilesDataForGraph(filteredDataForSSPs);
      calculateDecilesDataForPie(filteredDataForSSPs);

      // Get list for bidders selection
      let bidders = ['Combined data'];
      filteredDataForSSPs.map(row => {
        let match = row.Dimension.CUSTOM_CRITERIA.match(/(hb_bidder|stpd_bidder)=(\w+)/);
        let bidder = match ? match[2] : null;

        if (!bidder) {
          // Safeguard no to include unlisted bidders (not hb_bidder|stpd_bidder)
          return;
        }

        if (!bidders.includes(bidder)) {
          bidders.push(bidder);
        }
      });

      setBidders(bidders);
    } else {
      setError('Error getting Deciles data');
    }
  };

  const calculateDecilesDataForGraph = (decilesData = decilesData, calculateBidder = 'bidder') => {
    let googleData = [];

    if (calculateBidder === 'Combined data') {
      calculateBidder = 'bidder';
    }

    // Filter by bidders selection
    let filteredData = decilesData.filter(row =>
      row.Dimension.CUSTOM_CRITERIA.includes(calculateBidder)
    );

    filteredData.map(row => {
      googleData.push({
        passbacks: row.Dimension.LINE_ITEM_NAME.includes('passback')
          ? row.Column.TOTAL_LINE_ITEM_LEVEL_IMPRESSIONS
          : 0,
        imp: row.Column.TOTAL_LINE_ITEM_LEVEL_IMPRESSIONS,
        eCPM: parseFloat(row.Column.TOTAL_LINE_ITEM_LEVEL_WITHOUT_CPD_AVERAGE_ECPM) / 1000000,
        revenue: row.TOTAL_LINE_ITEM_LEVEL_CPM_AND_CPC_REVENUE / 1000000,
      });
    });

    // Sort data by eCPM
    googleData.sort((a, b) => (a.eCPM > b.eCPM ? 1 : -1));

    // Get total impressions count
    let totalImpressions = googleData.reduce((sum, eCPM) => parseFloat(eCPM.imp) + sum, 0);

    // Get total revenue
    let totalRevenue = googleData.reduce((sum, eCPM) => parseFloat(eCPM.revenue) + sum, 0);

    // Get decile impression ammount
    let decileImpressionAmmount = totalImpressions / 10;

    let decileRanges = {};
    let impressionsInRage = 0;
    let currentDecileRange = 0;
    let lastCPM = {
      eCPM: 0,
    };

    let fixedIntervals = [0.01, 0.1, 1, 10]
      .map(start => {
        let generatedRanges = [];
        for (let step = 1; step < 10; step++) {
          generatedRanges.push(start * step);
        }
        return [...generatedRanges];
      })
      .flat();
    fixedIntervals.unshift(0);

    //Generate periods if fixed intervals mode
    if (intervalsMode === 1) {
      fixedIntervals.map((price, i) => {
        decileRanges[i] = [
          {
            eCPM: {
              eCPM: price.toFixed(2),
              imp: '0',
              passbacks: 0,
              revenue: 0,
            },
          },
        ];
      });

      decileRanges[fixedIntervals.length] = [
        {
          eCPM: {
            eCPM: 100,
            imp: '0',
            passbacks: 0,
            revenue: 0,
          },
        },
      ];
    }

    googleData.map(eCPM => {
      if (intervalsMode === 1) {
        if (fixedIntervals[currentDecileRange] < eCPM.eCPM) {
          currentDecileRange = currentDecileRange + 1;
        }

        decileRanges[currentDecileRange].push({
          eCPM,
        });
      }

      if (intervalsMode === 0) {
        if (
          impressionsInRage + parseInt(eCPM.imp) <= decileImpressionAmmount ||
          eCPM.eCPM === lastCPM.eCPM
        ) {
          // Create new range if doesnt exist
          if (!decileRanges[currentDecileRange]) {
            decileRanges[currentDecileRange] = [];
          }

          // Add to same decile range
          impressionsInRage = impressionsInRage + parseInt(eCPM.imp);

          decileRanges[currentDecileRange].push({
            eCPM,
          });
        } else {
          // Move to another decile range
          impressionsInRage = 0;
          currentDecileRange = currentDecileRange + 1;

          // Add to same decile range
          impressionsInRage = impressionsInRage + parseInt(eCPM.imp);

          // Create new range if doesnt exist
          if (!decileRanges[currentDecileRange]) {
            decileRanges[currentDecileRange] = [];
          }

          decileRanges[currentDecileRange].push({
            eCPM,
          });
        }

        lastCPM = eCPM;
      }
    });

    let decilesDataForGraph = [];
    Object.keys(decileRanges).map(range => {
      let currentRange = parseInt(range);
      let nextRange = currentRange + 1;

      let passbacks = decileRanges[currentRange].reduce(
        (sum, rangeData) => parseInt(rangeData.eCPM.passbacks) + sum,
        0
      );
      let imp =
        decileRanges[currentRange].reduce(
          (sum, rangeData) => parseInt(rangeData.eCPM.imp) + sum,
          0
        ) - passbacks;
      let rev = decileRanges[currentRange].reduce(
        (sum, rangeData) => parseFloat(rangeData.eCPM.revenue) + sum,
        0
      );

      decilesDataForGraph.push({
        passbacks: passbacks,
        revShare: `${((rev * 100) / totalRevenue).toFixed(2)}%`,
        impShare: `${((imp * 100) / totalImpressions).toFixed(2)}%`,
        passbacksShare: `${((passbacks * 100) / totalImpressions).toFixed(2)}%`,
        imp: imp,
        revenue: rev,
        eCPM: decileRanges[currentRange][0].eCPM.eCPM,
        eCPMrange: `${decileRanges[currentRange][0].eCPM.eCPM} ${
          decileRanges[nextRange]
            ? `- ${decileRanges[nextRange][0].eCPM.eCPM}`
            : `- ${decileRanges[currentRange][decileRanges[currentRange].length - 1].eCPM.eCPM}`
        }`,
      });
    });

    setCalculatedData(decilesDataForGraph);
  };

  const calculateDecilesDataForPie = (
    decilesData = decilesData,
    calculateBidder = 'bidder',
    CPM = props.eCPM
  ) => {
    let googleData = [];

    if (calculateBidder === 'Combined data') {
      calculateBidder = 'bidder';
    }

    // Filter by bidders selection BUT EXCLUDE S2S
    let filteredData = decilesData.filter(
      row =>
        row.Dimension.CUSTOM_CRITERIA.includes(calculateBidder) &&
        !row.Dimension.CUSTOM_CRITERIA.includes('S2S')
    );

    filteredData.map(row => {
      googleData.push({
        passbacks: row.Dimension.LINE_ITEM_NAME.includes('passback')
          ? row.Column.TOTAL_LINE_ITEM_LEVEL_IMPRESSIONS
          : 0,
        imp: row.Column.TOTAL_LINE_ITEM_LEVEL_IMPRESSIONS,
        eCPM: parseFloat(row.Column.TOTAL_LINE_ITEM_LEVEL_WITHOUT_CPD_AVERAGE_ECPM) / 1000000,
        revenue: row.TOTAL_LINE_ITEM_LEVEL_CPM_AND_CPC_REVENUE / 1000000,
      });
    });

    // Sort data by eCPM
    googleData.sort((a, b) => (a.eCPM > b.eCPM ? 1 : -1));

    // Get total impressions count
    let totalImpressions = googleData.reduce((sum, eCPM) => parseFloat(eCPM.imp) + sum, 0);

    // Get total revenue
    let totalRevenue = googleData.reduce((sum, eCPM) => parseFloat(eCPM.revenue) + sum, 0);

    let decileRanges = {};
    let impressionsInRage = 0;
    let currentDecileRange = 0;
    let lastCPM = {
      eCPM: 0,
    };

    googleData.map(eCPM => {
      //parseInt(eCPM.eCPM) <= parseInt(props.eCPM)
      if (parseFloat(eCPM.eCPM) < parseFloat(CPM) || currentDecileRange === 1) {
        // Add to same decile range
        // Create new range if doesnt exist
        if (!decileRanges[currentDecileRange]) {
          decileRanges[currentDecileRange] = [];
        }

        impressionsInRage = impressionsInRage + parseInt(eCPM.imp);

        decileRanges[currentDecileRange].push({
          eCPM,
        });
      } else {
        // Move to another decile range
        impressionsInRage = 0;
        currentDecileRange = currentDecileRange + 1;

        // Add to same decile range
        impressionsInRage = impressionsInRage + parseInt(eCPM.imp);

        // Create new range if doesnt exist
        if (!decileRanges[currentDecileRange]) {
          decileRanges[currentDecileRange] = [];
        }

        decileRanges[currentDecileRange].push({
          eCPM,
        });
      }

      lastCPM = eCPM;
    });

    let decilesDataForGraph = [];
    Object.keys(decileRanges).map(range => {
      let currentRange = parseInt(range);
      let nextRange = currentRange + 1;

      let imp = decileRanges[currentRange].reduce(
        (sum, rangeData) => parseInt(rangeData.eCPM.imp) + sum,
        0
      );
      let rev = decileRanges[currentRange].reduce(
        (sum, rangeData) => parseFloat(rangeData.eCPM.revenue) + sum,
        0
      );
      let passbacks = decileRanges[currentRange].reduce(
        (sum, rangeData) => parseInt(rangeData.eCPM.passbacks) + sum,
        0
      );

      decilesDataForGraph.push({
        passbacks: passbacks,
        imp: imp,
        revenue: rev,
        maxInRange: decileRanges[currentRange][decileRanges[currentRange].length - 1].eCPM.eCPM,
        debug: [decileRanges[currentRange]],
        rangeStart: decileRanges[currentRange][0].eCPM.eCPM,
        eCPMrange: `${decileRanges[currentRange][0].eCPM.eCPM} ${
          decileRanges[nextRange] ? `- ${decileRanges[nextRange][0].eCPM.eCPM}` : `<`
        }`,
        eCPMrangeForPies: `eCPM ${decileRanges[currentRange][0].eCPM.eCPM}  ${
          decileRanges[nextRange]
            ? `- ${decileRanges[nextRange][0].eCPM.eCPM}€`
            : `- ${decileRanges[currentRange][decileRanges[currentRange].length - 1].eCPM.eCPM}€`
        }\nRevenue ${rev.toFixed(0)}€ (${((rev * 100) / totalRevenue).toFixed(
          0
        )}%)\nImpressions ${millify(imp)} (${((imp * 100) / totalImpressions).toFixed(0)}%)`,
      });
    });

    setCalculatedPieData(decilesDataForGraph);
  };

  const getTotalRevenueBySSP = () => {
    let rev = 0;
    let imp = 0;

    decilesDataWithoutPassbacks.map(row => {
      rev = rev + parseFloat(row.TOTAL_LINE_ITEM_LEVEL_CPM_AND_CPC_REVENUE / 1000000);
      imp = imp + parseInt(row.Column.TOTAL_LINE_ITEM_LEVEL_IMPRESSIONS);
    });

    if (googleData.success) {
      rev = rev + googleData.data['Rev'];
      imp = imp + googleData.data['Paid Imp'];
    }

    if (
      props.login.include_low_CPM_GAM_campaigns &&
      props.login.include_low_CPM_GAM_campaigns === 1 &&
      setupadData.success &&
      setupadData.data['Rev'] &&
      setupadData.data['Paid Imp']
    ) {
      rev = rev + setupadData.data['Rev'];
      imp = imp + setupadData.data['Paid Imp'];
    }

    if (amazonData.success) {
      rev = rev + amazonData.data['Rev'];
      imp = imp + amazonData.data['Paid Imp'];
    }

    if (setupadPMP.success) {
      rev = rev + setupadPMP.data['Rev'];
      imp = imp + setupadPMP.data['Paid Imp'];
    }

    if (setupadOB.success) {
      rev = rev + setupadOB.data['Rev'];
      imp = imp + setupadOB.data['Paid Imp'];
    }

    if (propelMedia.success) {
      rev = rev + propelMedia.data['Rev'];
      imp = imp + propelMedia.data['Paid Imp'];
    }

    return {rev: rev, imp: imp};
  };

  // Calculate how much times more impressions there are without refreshes
  //const getRatioToMatchImpressions = () => getTotalRevenueBySSP()['imp'] / props.totalImpressions

  const changeSort = (key, keepDirection = false) => {
    setMobileViewAnchor(null);
    setSort(prevSort => {
      return {
        key: key,
        direction: keepDirection
          ? prevSort.direction
          : prevSort.direction === 'down'
          ? 'up'
          : 'down',
      };
    });
  };

  const generateRevenueBySSPTable = () => {
    let data = {};

    // // Calculate how much times more impressions there are without refreshes
    const impRatio = getTotalRevenueBySSP()['imp'] / props.totalImpressions;

    decilesDataWithoutPassbacks.map(row => {
      // Exclude passbacks from SSP table
      if (row.Dimension.LINE_ITEM_NAME.includes('passback')) {
        return;
      }

      let match = row.Dimension.CUSTOM_CRITERIA.match(/(hb_bidder|stpd_bidder)=(\w+)/);
      let bidder = match ? match[2] : null;

      if (!bidder) {
        // Safeguard no to include unlisted bidders (not hb_bidder|stpd_bidder)
        return;
      }

      if (bidder.toLowerCase().includes('s2s')) {
        bidder = 'setupad';
      }

      if (data[bidder]) {
        data[bidder].revenue =
          data[bidder].revenue + row.TOTAL_LINE_ITEM_LEVEL_CPM_AND_CPC_REVENUE / 1000000;
        data[bidder].impressions =
          data[bidder].impressions + parseInt(row.Column.TOTAL_LINE_ITEM_LEVEL_IMPRESSIONS);
      } else {
        data[bidder] = {
          revenue: row.TOTAL_LINE_ITEM_LEVEL_CPM_AND_CPC_REVENUE / 1000000,
          impressions: parseInt(row.Column.TOTAL_LINE_ITEM_LEVEL_IMPRESSIONS),
        };
      }
    });

    if (googleData.success) {
      data['Google'] = {
        revenue: googleData.data['Rev'],
        impressions: googleData.data['Paid Imp'],
      };
    }

    if (amazonData.success && amazonData.data['Rev'] && amazonData.data['Paid Imp']) {
      data['Amazon'] = {
        revenue: amazonData.data['Rev'],
        impressions: amazonData.data['Paid Imp'],
      };
    }

    if (setupadPMP.success && setupadPMP.data['Rev'] && setupadPMP.data['Paid Imp']) {
      data['setupad'] = {
        revenue: (data['setupad'] ? data['setupad'].revenue : 0) + setupadPMP.data['Rev'],
        impressions:
          (data['setupad'] ? data['setupad'].impressions : 0) + setupadPMP.data['Paid Imp'],
      };
    }

    if (setupadOB.success && setupadOB.data['Rev'] && setupadOB.data['Paid Imp']) {
      data['setupad'] = {
        revenue: (data['setupad'] ? data['setupad'].revenue : 0) + setupadOB.data['Rev'],
        impressions:
          (data['setupad'] ? data['setupad'].impressions : 0) + setupadOB.data['Paid Imp'],
      };
    }

    if (propelMedia.success && propelMedia.data['Rev'] && propelMedia.data['Paid Imp']) {
      data['setupad'] = {
        revenue: (data['setupad'] ? data['setupad'].revenue : 0) + propelMedia.data['Rev'],
        impressions:
          (data['setupad'] ? data['setupad'].impressions : 0) + propelMedia.data['Paid Imp'],
      };
    }

    if (
      props.login.include_low_CPM_GAM_campaigns &&
      props.login.include_low_CPM_GAM_campaigns === 1 &&
      setupadData.success &&
      setupadData.data['Rev'] &&
      setupadData.data['Paid Imp']
    ) {
      data['setupad'] = {
        revenue: (data['setupad'] ? data['setupad'].revenue : 0) + setupadData.data['Rev'],
        impressions:
          (data['setupad'] ? data['setupad'].impressions : 0) + setupadData.data['Paid Imp'],
      };
    }

    // Add eCPM calculations
    data = Object.entries(data).map(bidder => {
      let adjustedImpressions = (bidder[1].impressions / impRatio).toFixed();

      bidder[1].impressions = adjustedImpressions;
      bidder[1].eCPM = ((bidder[1].revenue / adjustedImpressions) * 1000).toFixed(2);
      bidder[1].impShare = (
        (adjustedImpressions * 100) /
        (getTotalRevenueBySSP()['imp'] / impRatio)
      ).toFixed(2);
      bidder[1].revShare = ((bidder[1].revenue * 100) / getTotalRevenueBySSP()['rev']).toFixed(2);
      return bidder;
    });

    // // Sort data
    let sortedData = data.sort((a, b) => {
      return sort.direction === 'down'
        ? b[1][sort.key] - a[1][sort.key]
        : a[1][sort.key] - b[1][sort.key];
    });

    return (
      <>
        <Typography variant={'h6'} align={'left'} style={{marginBottom: 10}}>
          Distribution by demand
          <Tooltip
            arrow
            interactive
            enterTouchDelay={isMobile ? 0 : undefined}
            title={`Total of each SSP winning bids after competing in a header bidding auction.`}
          >
            <InfoIcon
              style={{opacity: 0.5, left: 5, top: 4, position: 'relative'}}
              fontSize={'small'}
            />
          </Tooltip>
        </Typography>

        <Grid item xs={12} style={{marginBottom: 15, textAlign: 'left'}}>
          <PeriodPicker isMobile={isMobile} />
        </Grid>

        {isMobile && (
          <Grid container style={{marginBottom: 20}} alignItems="center">
            <Grid item xs={12}>
              <>
                <Button
                  className={classes.button}
                  fullWidth
                  color={'primary'}
                  style={{float: 'right'}}
                  variant={'contained'}
                  endIcon={<ExpandMoreIcon />}
                  onClick={e => setMobileViewAnchor(e.currentTarget)}
                >
                  {sort.key
                    .replace('impShare', 'Impressions share')
                    .replace('revShare', 'Revenue share')}
                </Button>
                <Menu
                  anchorEl={mobileViewAnchor}
                  keepMounted
                  open={Boolean(mobileViewAnchor)}
                  onClose={() => setMobileViewAnchor(null)}
                >
                  <MenuItem onClick={() => changeSort('eCPM', true)}>eCPM</MenuItem>
                  <MenuItem onClick={() => changeSort('impressions', true)}>Impressions</MenuItem>
                  <MenuItem onClick={() => changeSort('impShare', true)}>
                    Impressions share
                  </MenuItem>
                  <MenuItem onClick={() => changeSort('revenue', true)}>Revenue</MenuItem>
                  <MenuItem onClick={() => changeSort('revShare', true)}>Revenue share</MenuItem>
                </Menu>
              </>
            </Grid>
          </Grid>
        )}

        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <StyledTableCell style={{fontWeight: 'bold'}}>Demand</StyledTableCell>
                {(!isMobile || sort.key === 'eCPM') && (
                  <StyledTableCell
                    onClick={() => changeSort('eCPM')}
                    align="right"
                    style={{
                      cursor: 'pointer',
                      fontWeight: 'bold',
                      borderBottom: `3px solid ${theme.palette.graphColors.eCPM}`,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        flexWrap: `wrap`,
                      }}
                    >
                      {sort.key === 'eCPM' ? (
                        sort.direction === 'up' ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )
                      ) : (
                        <ExpandLessIcon style={{opacity: 0}} />
                      )}
                      eCPM
                    </div>
                  </StyledTableCell>
                )}
                {(!isMobile || sort.key === 'impressions') && (
                  <StyledTableCell
                    onClick={() => changeSort('impressions')}
                    align="right"
                    style={{fontWeight: 'bold', cursor: 'pointer'}}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        flexWrap: `wrap`,
                      }}
                    >
                      {sort.key === 'impressions' ? (
                        sort.direction === 'up' ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )
                      ) : (
                        <ExpandLessIcon style={{opacity: 0}} />
                      )}
                      Impressions
                    </div>
                  </StyledTableCell>
                )}
                {(!isMobile || sort.key === 'impShare') && (
                  <StyledTableCell
                    onClick={() => changeSort('impShare')}
                    align="right"
                    style={{fontWeight: 'bold', cursor: 'pointer'}}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        flexWrap: `wrap`,
                      }}
                    >
                      {sort.key === 'impShare' ? (
                        sort.direction === 'up' ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )
                      ) : (
                        <ExpandLessIcon style={{opacity: 0}} />
                      )}
                      Impressions share
                    </div>
                  </StyledTableCell>
                )}
                {(!isMobile || sort.key === 'revenue') && (
                  <StyledTableCell
                    onClick={() => changeSort('revenue')}
                    align="right"
                    style={{
                      fontWeight: 'bold',
                      cursor: 'pointer',
                      borderBottom: `3px solid ${theme.palette.graphColors.revenue}`,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        flexWrap: `wrap`,
                      }}
                    >
                      {sort.key === 'revenue' ? (
                        sort.direction === 'up' ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )
                      ) : (
                        <ExpandLessIcon style={{opacity: 0}} />
                      )}
                      Revenue
                    </div>
                  </StyledTableCell>
                )}
                {(!isMobile || sort.key === 'revShare') && (
                  <StyledTableCell
                    onClick={() => changeSort('revShare')}
                    align="right"
                    style={{fontWeight: 'bold', cursor: 'pointer'}}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        flexWrap: `wrap`,
                      }}
                    >
                      {sort.key === 'revShare' ? (
                        sort.direction === 'up' ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )
                      ) : (
                        <ExpandLessIcon style={{opacity: 0}} />
                      )}
                      Revenue share
                    </div>
                  </StyledTableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map(row => {
                return (
                  <TableRow
                    onClick={() => {
                      if (row[0] !== 'Google' && row[0] !== 'Amazon' && row[0] !== 'Setupad') {
                        setSelectedBidder(row[0]);
                        ReactGA.event({
                          category: 'Deciles Change Bidder from Table',
                          action: 'click',
                          label: row[0],
                        });
                        calculateDecilesDataForGraph(decilesData, row[0]);
                        calculateDecilesDataForPie(decilesData, row[0]);
                      }
                    }}
                    style={{cursor: 'pointer'}}
                    key={row[0]}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      style={{textTransform: 'capitalize'}}
                    >
                      {getFavIconForSSP(getSSPname(row[0]))} {getSSPname(row[0])}
                    </StyledTableCell>

                    {(!isMobile || sort.key === 'eCPM') && (
                      <StyledTableCell
                        className={sort.key === 'eCPM' ? classes.sorted : ''}
                        align="right"
                      >
                        {row[1].eCPM}€
                      </StyledTableCell>
                    )}

                    {(!isMobile || sort.key === 'impressions') && (
                      <StyledTableCell
                        className={sort.key === 'impressions' ? classes.sorted : ''}
                        align="right"
                      >
                        {millify(row[1].impressions)}
                      </StyledTableCell>
                    )}

                    {(!isMobile || sort.key === 'impShare') && (
                      <StyledTableCell
                        className={sort.key === 'impShare' ? classes.sorted : ''}
                        align="right"
                      >
                        {row[1].impShare}%
                      </StyledTableCell>
                    )}

                    {(!isMobile || sort.key === 'revenue') && (
                      <StyledTableCell
                        className={sort.key === 'revenue' ? classes.sorted : ''}
                        align="right"
                      >
                        {row[1].revenue.toFixed(2)}€
                      </StyledTableCell>
                    )}

                    {(!isMobile || sort.key === 'revShare') && (
                      <StyledTableCell
                        className={sort.key === 'revShare' ? classes.sorted : ''}
                        align="right"
                      >
                        {row[1].revShare}%
                      </StyledTableCell>
                    )}
                  </TableRow>
                );
              })}

              <TableRow>
                <TableCell style={{fontWeight: 'bold'}} component="th" scope="row">
                  Total
                </TableCell>
                {!isMobile && <TableCell style={{fontWeight: 'bold'}} align="right"></TableCell>}
                {(!isMobile || sort.key === 'impressions') && (
                  <TableCell style={{fontWeight: 'bold'}} align="right">
                    {millify(props.totalImpressions)}
                  </TableCell>
                )}
                {!isMobile && <TableCell style={{fontWeight: 'bold'}} align="right"></TableCell>}
                {(!isMobile || sort.key === 'revenue') && (
                  <TableCell style={{fontWeight: 'bold'}} align="right">
                    {getTotalRevenueBySSP()['rev'].toFixed(2)}€
                  </TableCell>
                )}
                {!isMobile && <TableCell align="right"></TableCell>}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  return (
    <div style={{padding: '0 40px 40px 40px', width: '100%', textAlign: 'center'}}>
      {(loading || props.loadingTotals) && (
        <>
          <FadeIn>
            <Typography variant={'caption'}>Loading revenue distribution</Typography>
            <br />
            <CircularProgress color="primary" />
          </FadeIn>
        </>
      )}
      {error && (
        <>
          <Typography variant={'caption'}>Error getting revenue distribution data</Typography>
        </>
      )}

      {decilesData && !props.loadingTotals && (
        <>
          <FadeIn>
            {calculatedData && calculatedPieData && !isMobile && !props.demandOnly && (
              <Grid container>
                {/* {decilesData && props.login.username === 'demo' && <LockedFeature />} */}
                <Grid item md={12} lg={12} xl={12}>
                  <Typography variant={'h6'} align={'left'} style={{marginBottom: 10}}>
                    Revenue distribution
                    <Tooltip
                      arrow
                      interactive
                      title={
                        <React.Fragment>
                          {intervalsMode === 0
                            ? `Revenues per deciles are
                          allocated according to each impression eCPM value. The number of
                          impressions is similar in each decile, however, the eCPM ranges can be
                          different. Data gathered from supply-side platforms, excluding Google Ad
                          Exchange.
                         `
                            : `Revenues per fixed interval are counted as sum of all impressions' winning bid values that fall into particular eCPM interval.  The intervals remain fixed while the distribution of impressions may change. Data is gathered from all demand excluding Google Ad Exchange.`}
                          <br />
                          <Link
                            href="https://setupad.com/blog/avarage-ecpm-missleading-for-publishers/"
                            target={'_blank'}
                            color="primary"
                          >
                            Read more
                          </Link>
                        </React.Fragment>
                      }
                    >
                      <InfoIcon
                        style={{opacity: 0.5, left: 5, top: 4, position: 'relative'}}
                        fontSize={'small'}
                      />
                    </Tooltip>
                  </Typography>
                  {/*({selection.label.replace('Placement ', '')})*/}

                  <Grid item xs={12} style={{marginBottom: 15, textAlign: 'left'}}>
                    <PeriodPicker isMobile={isMobile} />
                    {props.login.revenue_distribution_fixed_intervals === 1 && (
                      <div style={{display: 'inline-block', marginLeft: 30}}>
                        <Typography display={'inline'} variant={'caption'}>
                          Deciles
                        </Typography>
                        <Switch
                          color={'inherit'}
                          checked={intervalsMode === 1}
                          onChange={() => {
                            ReactGA.event({
                              category: 'SSP interval type change',
                              action: 'click',
                              label: intervalsMode === 0 ? 'fixed' : 'deciles',
                            });
                            setInvervalsMode(intervalsMode === 0 ? 1 : 0);
                          }}
                        />
                        <Typography display={'inline'} variant={'caption'}>
                          Fixed intervals
                        </Typography>
                      </div>
                    )}

                    {bidders && (
                      <FormControl className={classes.formControl}>
                        {/* <InputLabel style={{width: `120%`}}>Selected SSP</InputLabel> */}
                        <Select
                          value={selectedBidder}
                          onChange={change => {
                            ReactGA.event({
                              category: 'Deciles Change Bidder from Dopdown',
                              action: 'click',
                              label: change.target.value,
                            });
                            setSelectedBidder(change.target.value);
                            calculateDecilesDataForGraph(decilesData, change.target.value);
                            calculateDecilesDataForPie(decilesData, change.target.value);
                          }}
                        >
                          {bidders.map(bidder => {
                            if (!bidder?.includes('S2S')) {
                              return <MenuItem value={bidder}>{getSSPname(bidder)}</MenuItem>;
                            }
                          })}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  <DecilesTable data={calculatedData} />
                </Grid>
                <Grid item md={12} lg={12} xl={12}>
                  {bidders && calculatedPieData[0] && (
                    <>
                      <Grid item xs={12}>
                        <Typography variant={'h6'} align={'left'}>
                          Cumulative distribution
                          <Tooltip
                            arrow
                            title={
                              'The cumulative result of revenues and impressions divided into two parts. One below the selected CPM price (left side) and another above it (right side). Data gathered from supply-side platforms, excluding Google Ad Exchange.'
                            }
                          >
                            <InfoIcon
                              style={{opacity: 0.5, left: 5, top: 4, position: 'relative'}}
                              fontSize={'small'}
                            />
                          </Tooltip>
                        </Typography>
                      </Grid>
                      <FormControl
                        className={classes.formControl}
                        style={{float: 'left', marginLeft: 0}}
                      >
                        {/* <InputLabel style={{width: `120%`}}>Selected SSP</InputLabel> */}
                        <Select
                          value={selectedBidder}
                          onChange={change => {
                            ReactGA.event({
                              category: 'Deciles Change Bidder from Dopdown',
                              action: 'click',
                              label: change.target.value,
                            });
                            setSelectedBidder(change.target.value);
                            calculateDecilesDataForGraph(decilesData, change.target.value);
                            calculateDecilesDataForPie(decilesData, change.target.value);
                          }}
                        >
                          {bidders.map(bidder => {
                            if (!bidder.includes('S2S')) {
                              return <MenuItem value={bidder}>{getSSPname(bidder)}</MenuItem>;
                            }
                          })}
                        </Select>
                      </FormControl>
                      <br />
                      <br />
                      <br />

                      {calculated && calculatedData && selectedBidder && decilesData && (
                        <CMPvisualiser
                          selectedBidder={selectedBidder}
                          decilesData={decilesData}
                          eCPM={((calculated.rev / calculated.paidImp) * 1000).toFixed(2)}
                          data={calculatedPieData}
                          recalculate={calculateDecilesDataForPie}
                        />
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            )}

            {/*{calculatedPieData &&*/}
            {/*<CMPvisualiser eCPM={props.eCPM} data={calculatedPieData}/>*/}
            {/*}*/}

            {props.login.ssp_table_enabled === 1 &&
              bidders &&
              calculatedPieData &&
              sort &&
              decilesData &&
              decilesDataWithoutPassbacks &&
              props.demandOnly === true && (
                <>
                  {/* {decilesData && props.login.username === 'demo' && <LockedFeature />} */}
                  <>{generateRevenueBySSPTable()}</>
                </>
              )}
          </FadeIn>
        </>
      )}
    </div>
  );
});

export default React.memo(DecilesView);
