// Number formatter, shortened to nf
// API returns some values INFINITY, or NaN, so we need to check if the value is a number else return 0
const nf = (value, metric) => {
  if (metric === 'date') {
    return value;
  }
  if (!isFinite(value) || isNaN(value)) {
    return 0;
  }

  return parseFloat(value);
};

// Returns day data in a structured format for the graph for fixed metrics
const getStructuredFixedGraphDataForTheDay = (day, metricsConfig) => {
  let metrics = {};
  Object.keys(metricsConfig.fixed).forEach(metric => {
    metrics[metric] = nf(day[metricsConfig.fixed[metric].dataField], metric);
  });
  return metrics;
};

const getAvailableSources = (data, sourcesConfig) => {
  let getAvailableSources = [];
  data.forEach(day => {
    // Not all the sources are always present in the data, so we need to know what's present
    Object.keys(sourcesConfig).forEach(source => {
      if (day[`${source} Paid Imp`]) {
        if (!getAvailableSources.includes(source)) {
          getAvailableSources.push(source);
        }
      }
    });
  });
  return getAvailableSources;
};

// Returns day data in a structured format for the graph by the available sources
const getStructuredDynamicGraphDataForTheDay = (
  day,
  availableSources,
  metricsConfig,
  disabledSources
) =>
  availableSources.reduce((metrics, source) => {
    Object.keys(metricsConfig.perSource).forEach(metric => {
      let config = metricsConfig.perSource[metric];
      let key = `${source} ${config.dataField}`;

      if (metrics[config.dataField] === undefined) {
        metrics[config.dataField] = 0;
        metrics[key] = 0;
      }

      if (!disabledSources.includes(source)) {
        if (metric === 'impressions') {
          if (metrics['enabledSources_Paid Imp'] === undefined) {
            metrics['enabledSources_Paid Imp'] = 0;
          }
          metrics['enabledSources_Paid Imp'] += nf(day[key]);
        }

        if (metric === 'revenue') {
          if (metrics['enabledSources_Rev'] === undefined) {
            metrics['enabledSources_Rev'] = 0;
          }
          metrics['enabledSources_Rev'] += nf(day[key]);
        }
      }

      metrics[config.dataField] += nf(day[key]);
      metrics[key] = nf(day[key]);
    });

    // Add total average eCPM
    metrics['enabledSources_eCPM'] = nf(
      (metrics['enabledSources_Rev'] / metrics['enabledSources_Paid Imp']) * 1000
    ).toFixed(2);

    return metrics;
  }, {});

// Returns totals by the available sources from daily data
const getTotals = (graphData, availableSources, metricsConfig, disabledSources = []) => {
  let totals = {
    perSource: {},
  };

  graphData.map(day => {
    availableSources.map(source => {
      if (disabledSources.includes(source)) return null;

      Object.keys(metricsConfig.perSource).forEach(metric => {
        let config = metricsConfig.perSource[metric];
        let key = `${source} ${config.dataField}`;

        // Don't sum up non-cumulative metrics
        if (!config.cumulative) {
          return;
        }

        let value = day[key];

        // Total by source
        // Initialize total metric by source
        if (!totals[`perSource`][key]) {
          totals[`perSource`][key] = 0;
        }

        // Set total metric by source
        totals[`perSource`][key] += value;

        // Total by metric
        // Initialize the total for the metric
        if (!totals[config.dataField]) {
          totals[config.dataField] = 0;
        }

        // Set total for the metric
        totals[config.dataField] += value;
      });
    });
  });

  // Calculate total average eCPM
  totals['eCPM'] = ((totals['Rev'] / totals['Paid Imp']) * 1000).toFixed(2);

  // Calculate eCPM per source
  availableSources.map(source => {
    let key = `${source} eCPM`;
    totals[`perSource`][key] = (
      (totals[`perSource`][`${source} Rev`] / totals[`perSource`][`${source} Paid Imp`]) *
      1000
    ).toFixed(2);
  });

  return totals;
};

// Returns percents, first param is value, another is sum
const getPercents = (value, sum) => {
  if (sum === 0) {
    return 0;
  }

  return ((value / sum) * 100).toFixed(2);
};

const getMaxValueForGraph = (data, metricsConfig, sourcesConfig) => {
  let max = 0;

  data.forEach(day => {
    if (!metricsConfig.cumulative) {
      Object.keys(sourcesConfig).forEach(source => {
        let key = `${source} ${metricsConfig.dataField}`;
        let value = day[key];
        if (value > max) {
          max = value;
        }
      });
    } else {
      let key = `${metricsConfig.dataField}`;
      let value = day[key];
      if (value > max) {
        max = value;
      }
    }
  });

  return max;
};

module.exports = {
  getStructuredFixedGraphDataForTheDay,
  getAvailableSources,
  getStructuredDynamicGraphDataForTheDay,
  getTotals,
  getPercents,
  getMaxValueForGraph,
};
