import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Container, Grid, TextField, Typography} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {APICall} from '../api/api';
import ArticleCard from '../components/subcomponents/articles/ArticleCard';
import ArticleCardSkeleton from '../components/subcomponents/articles/ArticleCardSkeleton';
import {useDebouncedCallback} from 'use-debounce';

const useStyles = makeStyles(theme => ({
  wrapper: {
    borderRadius: '4px',
  },
  viewWrapper: {
    padding: 50,
    [theme.breakpoints.down('sm')]: {
      padding: 15,
      paddingTop: 10,
    },
  },
  searchInputField: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const Articles = ({login}) => {
  const classes = useStyles();
  const [articles, setArticles] = React.useState(null);
  const [searchValue, setSearchValue] = React.useState(null);
  const [filteredArticles, setFilteredArticles] = React.useState(null);

  useEffect(() => {
    async function loadArticles() {
      let articles = await APICall({
        endpoint: 'publicArticlesList',
        options: {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${login.token}`,
            'Cache-Control': 'max-age=0, no-cache',
          },
        },
      });

      setArticles(articles);
      setFilteredArticles(articles);
    }

    loadArticles();
  }, []);

  useEffect(() => {
    if (searchValue) {
      filterArticles();
    } else {
      setFilteredArticles(articles);
    }
  }, [searchValue]);

  const [filterArticles] = useDebouncedCallback(() => {
    setFilteredArticles({
      ...filteredArticles,
      result: articles.result.filter(article =>
        article.title.toLowerCase().includes(searchValue.toLowerCase())
      ),
    });
  }, 300);

  return (
    <Container component="main" maxWidth="xl" className={classes.viewWrapper}>
      {articles && !articles.success && (
        <Alert severity="error">Failed to load Articles, please try again.</Alert>
      )}

      <Typography variant={'h4'} gutterBottom>
        Setupad Knowledge Base
      </Typography>

      <Typography gutterBottom>
        Here you will find articles and guides regarding different topics related to Setupad
        products
      </Typography>

      <TextField
        label={`Search ${
          searchValue && articles ? `(${filteredArticles?.result.length} found)` : ''
        }`}
        fullWidth
        autoFocus
        variant={'filled'}
        disabled={!articles?.success}
        className={classes.searchInputField}
        value={searchValue}
        onChange={event => setSearchValue(event.target.value)}
      />

      {/* Show skeleton articles as loading takes a while */}
      {!articles && (
        <Grid container className={classes.wrapper} spacing={3} direction={'row'}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(index => (
            <ArticleCardSkeleton key={index} />
          ))}
        </Grid>
      )}

      {filteredArticles?.result.length === 0 && articles && (
        <Typography gutterBottom>No articles found</Typography>
      )}

      {filteredArticles && filteredArticles.success && (
        <Grid container className={classes.wrapper} spacing={3} direction={'row'}>
          {filteredArticles.result?.map((article, index) => (
            <ArticleCard
              key={index}
              id={article.id}
              title={article.title}
              image={article.cover_image}
              category={article.category}
              time={article.created_at}
            />
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default React.memo(Articles);
