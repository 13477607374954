const baseUrl = 'https://client.setupad.com/terms_and_conditions/';

const getTermsAndConditionsFileUrl = auth => {
  if (!auth) return null;

  let payer = auth?.billingDataCheck?.payer;
  if (!payer) {
    payer = 'lv';
  }
  // default value

  let type = auth?.type;

  let accountTypeName = 'wrapper';

  if (type >= 5 && type < 8) {
    accountTypeName = 'selfserve';
  } else if (type === 0) {
    accountTypeName = 'adapter';
  }

  let url = baseUrl + payer?.toLowerCase() + '/' + accountTypeName + '.pdf';

  return url;
};

export default getTermsAndConditionsFileUrl;
