import React from 'react';
import {ButtonGroup, Button, makeStyles} from '@material-ui/core';
import useStore from '../../store/store';

const useStyles = makeStyles(theme => ({
  wrapper: {
    marginLeft: 20,
    display: 'inline',
  },
  wrapperInDropdown: {
    float: 'left',
    left: 12,
    top: 7,
    position: 'relative',
  },
  button: {},
  buttonInDropDown: {
    fontSize: 12,
  },
}));

const DataTypeSelector = ({inDropdown}) => {
  const setState = useStore(state => state.setState);
  const dataType = useStore(state => state.dataType);
  const classes = useStyles();

  return (
    <ButtonGroup
      size={inDropdown ? 'small' : 'medium'}
      class={inDropdown ? classes.wrapperInDropdown : classes.wrapper}
    >
      {['setupad', 'combined', 'publisher'].map(type => (
        <Button
          onClick={() => setState('dataType', type)}
          variant={dataType === type ? 'contained' : 'outlined'}
          color={dataType === type ? 'primary' : null}
          className={inDropdown ? classes.buttonInDropDown : classes.button}
        >
          {type}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default DataTypeSelector;
