import React from 'react';
import {Link, Grid, Typography, Divider, IconButton, Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles/index';
import FadeIn from 'react-fade-in/lib/FadeIn';
import lscache from 'lscache';
import SS_promo from './../img/SS_promo.png';

const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: theme.spacing(3),
    marginBottom: theme.spacing(0),
    padding: theme.spacing(1),
    borderRadius: 8,
    width: 'auto',
    cursor: 'pointer',
    position: 'relative',
    border: `1px dashed ${theme.palette.graphColors.historicalBackground}`,
  },
  logo: {
    width: '100%',
    margin: '0 auto',
    display: 'block',
    borderRadius: 4,
  },
  close: {
    marginBottom: theme.spacing(1),
    margin: '0 auto',
    display: 'block',
  },
}));

// Should be shown to users who are at least 3 months old and are the following types: Sutupad Wrapper (1), Setupad Prebid (4), Self Serve Enterprise (7)
const allowedAccountTypes = [0];

const SelfServePromo = ({auth}) => {
  const classes = useStyles();
  const lsKey = `SS_promo_closed_${auth.accountId}`;
  let favorsRules = allowedAccountTypes.includes(auth.type) && !lscache.get(lsKey);

  const [visible, setVisible] = React.useState(favorsRules);

  const handleClose = () => {
    setVisible(false);
    lscache.set(lsKey, true, false);
  };

  // alert(auth.type);

  if (visible)
    return (
      <FadeIn>
        <Grid
          container
          spacing={2}
          className={classes.wrapper}
          alignItems="center"
          direction="column"
        >
          <Link
            href={`mailto:${auth.manager.email}?subject=Interested in the Self-Serve offer&body=Hello ${auth.manager.fullName}, I want to try Setupad Self-Serve with 60M ad requests for free. Please provide me with more information about getting started.`}
            target="_blank"
          >
            <img src={SS_promo} className={classes.logo} />
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Button variant={'text'} onClick={handleClose} className={classes.close}>
            <Typography variant={'caption'} color={'textSecondary'}>
              Close
            </Typography>
          </Button>
        </Grid>
        <Divider />
      </FadeIn>
    );

  return null;
};

export default SelfServePromo;
