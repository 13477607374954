import React, {useEffect, useRef} from 'react';
import {useDebouncedCallback} from 'use-debounce';
import {useMediaQuery} from 'react-responsive';
import FadeIn from 'react-fade-in';
import millify from 'millify';
import lscache from 'lscache';
import fileDownload from 'js-file-download';
import ReactGA from 'react-ga4';
import {APICall} from './../../api/api';
import breakpoints from './../helpers/breakpoints';
import indicatorStyles from '../helpers/indicatorsStyles';
import useStore from '../../store/store';
import AnalyticsIcon from './../img/ic_analytics.svg';
import Trend from './Trend';
import DecilesView from './DecilesView';
import Realtime from './Realtime';
import {PeriodPicker} from './PeriodPicker';
import MetricsDisplay from './MetricsDisplay_mobile';
import AccountsDetailsTable from './AccountsDetailsTable';
import AccountsPropertiesSelection from './AccountsPropertiesSelection';
import Placeholder from './../misc/Placeholder';
import {Info, Close, VerticalAlignBottom} from '@material-ui/icons';
import {
  Container,
  Typography,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Switch,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import {makeStyles, useTheme} from '@material-ui/core/styles/index';
import Link from '@material-ui/core/Link/index';
import {Alert, Skeleton} from '@material-ui/lab';
import moment from 'moment';
import {ShareDialog} from './ShareDialog';
import {TimezonePicker} from './TimezonePicker';
import usePublishers from '../../hooks/usePublishers';
import HistoricalDataAlert from './HistoricalDataAlert';
import DataTypeSelector from './DataTypeSelector';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  statsWrapper: {
    borderBottom: '1px solid #7979793b',
    cursor: 'pointer',
    paddingTop: theme.spacing(0.5),
  },
  metricWrapper: {
    marginBottom: theme.spacing(0.5),
  },
  metricWrapperAudience: {
    marginBottom: theme.spacing(1.5),
  },
  lowerOpacity: {
    opacity: 0.5,
  },
  containerWrapper: {
    borderRadius: 4,
    padding: `0px 20px 20px`,
    [theme.breakpoints.down('lg')]: {
      border: 'none',
      padding: 0,
    },
  },
  ...indicatorStyles(theme),
}));

const AccountDetails = React.memo(props => {
  const isMobile = useMediaQuery({query: breakpoints.mobile});
  const classes = useStyles();
  const theme = useTheme();
  const chartRef = useRef();
  const abortController = new AbortController();

  const dataType = useStore(state => state.dataType);
  const [calculatedComparison, setCalculatedComparison] = React.useState(null);
  const calculated = useStore(state => state.calculated);
  const setState = useStore(state => state.setState);
  const period = useStore(state => state.period);
  const comparisonPeriod = useStore(state => state.comparisonPeriod);
  const selection = useStore(state => state.selection);
  const [publisherStats, setPublisherStats] = React.useState(false);
  const [domainHistory, setDomainHistory] = React.useState(false);
  const [publisherStatsComparison, setPublisherStatsComparison] = React.useState(false);
  const [analyticsError, setAnalyticsError] = React.useState(false);
  const [analyticsLoaded, setAnalyticsLoaded] = React.useState(false);
  const [analyticsComparisonLoaded, setAnalyticsComparisonLoaded] = React.useState(false);
  const [analyticsReminderHidden, setAnalyticsReminderHidden] = React.useState(false);
  const [graphType, setGraphType] = React.useState(1);
  const [comparisonEnabled, setComparisonEnabled] = React.useState(
    isMobile ? true : lscache.get('comparisonEnabled')
  );
  const [activeMetrics, setActiveMetrics] = React.useState([
    'Rev',
    'All Imp',
    'Paid Imp',
    'eCPM',
    'fillRate',
    'Viewability',
  ]);
  const {publishersHasOwnGam} = usePublishers();

  const metricGroups = {
    // Show all
    0: [],
    // Monetization
    1: ['Rev', 'All Imp', 'Paid Imp', 'eCPM', 'fillRate'],
    // Performance
    2: ['Viewability', 'refreshRate', 'Rev Refresh', 'rCPM', 'CTR', 'eCPM Refresh'],
    // Analytics
    3: ['sRPM', 'uRPM', 'pvRPM', 'pageviewsPerSession', 'sessionsPerUser'],
    4: [
      'Rev',
      'All Imp',
      'Paid Imp',
      'eCPM',
      'Viewability',
      'Rev Refresh',
      'eCPM Refresh',
      'rCPM',
      'CTR',
      'sRPM',
      'uRPM',
      'pvRPM',
      'refreshRate',
      'fillRate',
    ],
  };

  useEffect(() => {
    // If placement selected see if Audience shouldn't be disabled
    if (selection.value.placement) {
      // Filter out any audience metrics, if none left turn on first metrics category
      let filteredActiveMetrics = activeMetrics.filter(
        activeMetric => !metricGroups[3].includes(activeMetric)
      );

      setActiveMetrics(filteredActiveMetrics.length > 0 ? filteredActiveMetrics : metricGroups[1]);
    }

    setAnalyticsError(false);
    setAnalyticsLoaded(false);
    setAnalyticsComparisonLoaded(false);
    setPublisherStats(false);
    setPublisherStatsComparison(false);
    setDomainHistory(false);
    setState('calculated', null);
    setCalculatedComparison(null);

    getPublisherStats(false);

    return () => abortController.abort();
  }, [selection, period, dataType]);

  useEffect(() => {
    if (calculated && calculatedComparison !== false) {
      getPublisherStats(true);
    }
  }, [calculated]);

  let getDomainHistory = async () => {
    try {
      let history = await APICall({
        endpoint: 'getDomainHistory',
        params: `${selection.value.domain}`,
        options: {
          signal: abortController.signal,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${props.login.token}`,
            'Cache-Control': 'max-age=0, no-cache',
            'Content-Type': 'application/json',
          },
        },
      });
      setDomainHistory(history);
    } catch (e) {
      console.log('error getting domain history', e);
    }
  };

  const getRouteByDataType = () => {
    switch (dataType) {
      case 'setupad':
        return 'totalclientsetupad';
      case 'combined':
        return 'totalclientown';
      case 'publisher':
        return 'totalclientpublisher';
    }
  };

  const getPublisherStats = async forComparison => {
    try {
      let query = await APICall({
        endpoint: [4, 5, 6, 7].includes(props.login.type) ? getRouteByDataType() : 'totalclient',
        params: `${forComparison ? comparisonPeriod.startDate : period.startDate}/${
          forComparison ? comparisonPeriod.endDate : period.endDate
        }${selection.value.publisher ? `/${selection.value.publisher}` : ``}${
          selection.value.domain ? `/${selection.value.domain}` : ``
        }${selection.value.placement ? `/${selection.value.placement}` : ``}`,
        options: {
          signal: abortController.signal,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${props.login.reportingToken}`,
            'Cache-Control': 'max-age=0, no-cache',
          },
        },
      });

      // Add calculations for additional metrics
      query.map((day, i) => {
        query[i].refreshRate =
          day['All Imp'] !== '0' && day['All Imp Refresh'] !== '0'
            ? (day['All Imp Refresh'] * 100) / day['All Imp']
            : 0;
        query[i].fillRate =
          day['All Imp'] !== '0' && day['Paid Imp'] !== 0
            ? (day['Paid Imp'] * 100) / day['All Imp']
            : 0;

        // Fill missing eCPM
        query[i].eCPM = day['eCPM'] ? day['eCPM'] : 0;

        // Fill missing rCPM
        query[i].rCPM = day['rCPM'] && day['rCPM'] !== 'NAN' ? day['rCPM'] : 0;
      });

      if (!forComparison) {
        setPublisherStats(query);

        // Ad another empty day if last period date is not equal to last date in data
        if (query.length > 0 && query[query.length - 1].date !== period.endDate) {
          let lastDayData = query[query.length - 1];
          let emptyRows = {};

          Object.keys(lastDayData).map(key => (emptyRows[key] = 0));

          query.push({
            //...lastDayData.map((row, key) => key),
            ...emptyRows,
            date: moment(lastDayData.date)
              .add(1, 'day')
              .format('YYYY-MM-DD'),
          });
        }
      } else {
        // Dont compare if data length for comparison is shorter than data to compare to
        if (query.length < publisherStats.length) {
          return;
        }

        setPublisherStatsComparison(query);
      }

      googleAnalyticsCheck(query, forComparison);
    } catch (error) {
      //console.log('getPublisherStats error', error.message);
      // // Redirect on response status not being 200
      //console.log('Error', error);
      if (error.message !== 'The user aborted a request.') {
        // history.push('/login');
      }
    }
  };

  let getAnalyticsStatsForProperty = async (dateFrom, dateTo, analyticsProperty) => {
    return await APICall({
      endpoint: 'getAnalyticsDataForDomain',
      options: {
        signal: abortController.signal,
        method: 'POST',
        body: JSON.stringify({
          analyticsProperty: analyticsProperty,
          dateFrom: dateFrom,
          dateTo: dateTo,
          required: 'both',
        }),
        headers: {
          Authorization: `Bearer ${props.login.token}`,
          'Cache-Control': 'max-age=0, no-cache',
          'Content-Type': 'application/json',
        },
      },
    });
  };

  let googleAnalyticsCheck = async (query, forComparison = false) => {
    // If analytics properties are there, return it as array. One if domain level is selected, all if Total for account is selected
    let analyticsProperties =
      props.assignedGoogleProperties !== 'error' &&
      props.assignedGoogleProperties.find(property => property.domainId == selection.value.domain)
        ? [
            props.assignedGoogleProperties.find(
              property => property.domainId == selection.value.domain
            ),
          ]
        : Object.keys(props.assignedGoogleProperties).length > 0 && !selection.value.publisher
        ? props.assignedGoogleProperties
        : null;

    // console.log('analyticsProperties', analyticsProperties, selection)

    // Get Analytics data if needed
    if (query.length > 0 && analyticsProperties) {
      let dateFrom = query[0].date;
      let dateTo = query[query.length - 1].date;

      // Load graph before GA gets loaded on desktop
      if (!isMobile) {
        calculate(query, 0, query.length, forComparison);
      }

      // Load all analytics properties data at once
      let analyticsQueries = await Promise.all(
        analyticsProperties.map(async analyticsProperty => {
          return await getAnalyticsStatsForProperty(dateFrom, dateTo, analyticsProperty);
        })
      );

      //console.log(`analyticsQuery`, analyticsQueries)

      // If none of analytics query failed, sum them up and merge with reports stats
      let errors = analyticsQueries.reduce((texts, query) => {
        if (query.total?.error) {
          texts.push(query.total.error);
        }
        if (query.daily?.error) {
          texts.push(query.daily.error);
        }
        return texts;
      }, []);

      if (!errors.length) {
        // Merge analytics query data
        let mergedAnalyticsStats = {};

        analyticsQueries.map(query => {
          query.daily.map(day => {
            if (!mergedAnalyticsStats[day.date]) {
              mergedAnalyticsStats[day.date] = {
                pageviews: parseInt(day.pageviews),
                visits: parseInt(day.visits),
                users: parseInt(day.users),
                pageviewsPerSession: parseFloat(day.pageviewsPerSession),
                sessionsPerUser: parseFloat(day.sessionsPerUser),
              };
            } else {
              mergedAnalyticsStats[day.date] = {
                pageviews: mergedAnalyticsStats[day.date].pageviews + parseInt(day.pageviews),
                visits: mergedAnalyticsStats[day.date].visits + parseInt(day.visits),
                users: mergedAnalyticsStats[day.date].users + parseInt(day.users),
                pageviewsPerSession: parseFloat(day.pageviewsPerSession),
                sessionsPerUser: parseFloat(day.sessionsPerUser),
              };
            }
          });
        });

        // Merge analytics totals
        let mergedAnalyticsTotals = {
          users: 0,
          sessionsPerUser: 0,
          visits: 0,
          pageviewsPerSession: 0,
        };

        analyticsQueries.map(query => {
          mergedAnalyticsTotals = {
            users: mergedAnalyticsTotals.users + parseInt(query.total.users),
            sessionsPerUser:
              mergedAnalyticsTotals.sessionsPerUser + parseFloat(query.total.sessionsPerUser),
            visits: mergedAnalyticsTotals.visits + parseInt(query.total.visits),
            pageviewsPerSession:
              mergedAnalyticsTotals.pageviewsPerSession +
              parseFloat(query.total.pageviewsPerSession),
          };
        });

        mergedAnalyticsTotals = {
          ...mergedAnalyticsTotals,
          sessionsPerUser: mergedAnalyticsTotals.sessionsPerUser / analyticsQueries.length,
          pageviewsPerSession: mergedAnalyticsTotals.pageviewsPerSession / analyticsQueries.length,
        };

        // Merge merged analytics data with reports stats
        query.map((row, index) => {
          query[index] = {...query[index], ...mergedAnalyticsStats[query[index].date]};
        });

        // Add calculations
        query.map((day, i) => {
          // Add eur per 1000 PW
          query[i].pvRPM = (day['Rev'] / (day['pageviews'] / 1000)).toFixed(2);
          query[i].sRPM = (day['Rev'] / (day['visits'] / 1000)).toFixed(2);
          query[i].uRPM = (day['Rev'] / (day['users'] / 1000)).toFixed(2);
        });

        if (forComparison) {
          setAnalyticsComparisonLoaded(true);
        }

        if (!forComparison) {
          setAnalyticsLoaded(true);
        }

        calculate(query, 0, query.length, forComparison, mergedAnalyticsTotals);
      } else {
        calculate(query, 0, query.length, forComparison);
        setAnalyticsError(errors.join(','));
        //console.log('analyticsQueries', analyticsQueries);
      }
    } else {
      // If analytics is not needed trigger calculate function anyways

      calculate(query, 0, query.length, forComparison);
    }
  };

  let calculate = (
    data,
    indexFrom,
    indexTo,
    forComparison = false,
    mergedAnalyticsTotals = false
  ) => {
    // Prevent crash when changing periods rapidly
    if (!data.length) {
      return;
    }

    data = data.slice(indexFrom, indexTo + 1);

    let calculations = {
      dateFrom: data[0].date,
      dateTo: data[data.length - 1].date,
      rev: data.reduce((total, day) => total + parseFloat(day.Rev), 0).toFixed(2),
      imp: data.reduce((total, day) => total + parseFloat(day['All Imp']), 0).toFixed(2),
      paidImp: data.reduce((total, day) => total + parseFloat(day['Paid Imp']), 0).toFixed(2),
      allImpRefresh: data.reduce((total, day) => total + parseFloat(day['All Imp Refresh']), 0),
      refreshRev: data
        .reduce(
          (total, day) => total + (day['Rev Refresh'] ? parseFloat(day['Rev Refresh']) : 0),
          0
        )
        .toFixed(2),
      viewability: (
        data
          .filter(day => parseFloat(day.Viewability) !== 0)
          .reduce(
            (total, day) => total + parseFloat(day['Paid Imp'] * parseFloat(day['Viewability'])),
            0
          ) /
        data
          .filter(day => parseFloat(day.Viewability) !== 0)
          .reduce((total, day) => total + parseFloat(day['Paid Imp']), 0)
      ).toFixed(2),
      CTR: (
        data
          .filter(day => parseFloat(day.CTR) !== 0)
          .reduce((total, day) => total + parseFloat(day['Paid Imp'] * parseFloat(day['CTR'])), 0) /
        data
          .filter(day => parseFloat(day.CTR) !== 0)
          .reduce((total, day) => total + parseFloat(day['Paid Imp']), 0)
      ).toFixed(2),
      refreshRate:
        props.login.refresh_enabled !== 1
          ? null
          : (data.reduce((total, day) => total + parseFloat(day['All Imp Refresh']), 0) * 100) /
            data.reduce((total, day) => total + parseFloat(day['All Imp']), 0),

      // Analytics calculation
      pageviews: data.reduce(
        (total, day) => total + (isNaN(day.pageviews) ? 0 : parseFloat(day.pageviews)),
        0
      ),
      users: mergedAnalyticsTotals
        ? mergedAnalyticsTotals.users
        : data.reduce((total, day) => total + (isNaN(day.users) ? 0 : parseFloat(day.users)), 0),
      visits: mergedAnalyticsTotals
        ? mergedAnalyticsTotals.visits
        : data.reduce((total, day) => total + (isNaN(day.visits) ? 0 : parseFloat(day.visits)), 0),
      pageviewsPerSession: mergedAnalyticsTotals
        ? mergedAnalyticsTotals.pageviewsPerSession
        : data.reduce(
            (total, day) =>
              total + (isNaN(day.pageviewsPerSession) ? 0 : parseFloat(day.pageviewsPerSession)),
            0
          ) / data.length,
      sessionsPerUser: mergedAnalyticsTotals
        ? mergedAnalyticsTotals.sessionsPerUser
        : data.reduce(
            (total, day) =>
              total + (isNaN(day.sessionsPerUser) ? 0 : parseFloat(day.sessionsPerUser)),
            0
          ) / data.length,
    };

    if (forComparison) {
      setCalculatedComparison(calculations);
    } else {
      setState('calculated', calculations);
    }
  };

  const [handleZoom] = useDebouncedCallback(event => {
    // console.log('graph props handleZoom', event)
    // calculate(publisherStats, event.startIndex, event.endIndex, false);
  }, 0);

  const generateList = () => {
    let menu = [];

    menu.push({
      label: `Account Total`,
      value: {
        publisher: null,
        domain: null,
        placement: null,
      },
    });

    props.login.menu.menu[1].children.map((domain, i) => {
      menu.push({
        label: `${domain.data.menu.alias} domain`,
        value: {
          publisher: domain.path[1],
          domain: domain.path[2],
          placement: null,
        },
      });

      domain.children.map(placement => {
        menu.push({
          label: `${domain.data.menu.alias} ${placement.data.menu.title}`,
          value: {
            publisher: domain.path[1],
            domain: domain.path[2],
            placement: placement.path,
          },
          customAdditionalLabel: domain.data.menu.alias,
        });
      });
    });

    return menu;
  };

  const toggleMetric = metric => {
    if (!activeMetrics.includes(metric)) {
      setActiveMetrics(currentMetrics => [...currentMetrics, metric]);
      ReactGA.event({
        category: 'Individual metric enable',
        action: 'click',
        label: metric,
      });
    } else {
      ReactGA.event({
        category: 'Individual metric disable',
        action: 'click',
        label: metric,
      });
      setActiveMetrics(currentMetrics =>
        currentMetrics.filter(currentMetric => currentMetric !== metric)
      );
    }

    chartRef.current.state.chart.graphs.map(graph => {
      if (graph.valueField === metric) {
        if (graph.hidden) {
          chartRef.current.state.chart.showGraph(graph);
        } else {
          chartRef.current.state.chart.hideGraph(graph);
        }
      }
    });
  };

  const handleGraphTypeChange = type => {
    let graphType = type;

    if (type.target) {
      graphType = type.target.value;
    }

    let metricsGroupName;
    if (type === 4) {
      metricsGroupName = 'All metrics';
    }
    if (type === 3) {
      metricsGroupName = 'Audience';
    }
    if (type === 2) {
      metricsGroupName = 'Performance';
    }
    if (type === 1) {
      metricsGroupName = 'Monetization';
    }

    ReactGA.event({
      category: 'metrics category change',
      action: 'click',
      label: metricsGroupName,
    });

    setGraphType(graphType);

    if (selection.value.placement) {
      let metrics = metricGroups[graphType].filter(
        activeMetric => !metricGroups[3].includes(activeMetric)
      );
      setActiveMetrics(metrics);
    } else {
      setActiveMetrics(metricGroups[graphType]);
    }

    if (!isMobile) {
      chartRef.current.state.chart.graphs.map(graph => {
        if (metricGroups[graphType].includes(graph.valueField) || graphType === 0) {
          //console.clear()
          //                    console.log(chartRef.current.state.chart)
          if (
            graph.hidden &&
            selection.value.placement &&
            !metricGroups[3].includes(graph.valueField)
          ) {
            chartRef.current.state.chart.showGraph(graph);
          }
        } else {
          if (!graph.hidden) {
            chartRef.current.state.chart.hideGraph(graph);
          }
        }
      });
    }
  };

  const exportXLS = async () => {
    ReactGA.event({
      category: 'Export to XLS',
      action: 'click',
    });

    // Get end and start dates from chart ref selection
    let startDate = publisherStats[chartRef.current.state.chart.startIndex].date;
    let endDate = publisherStats[chartRef.current.state.chart.endIndex].date;

    fetch(
      `https://reports.setupad.com/setupad/api/xls/${
        selection.value.domain ? `${selection.value.domain}` : `0`
      }/${
        selection.value.placement ? `${selection.value.placement}` : `0`
      }/${startDate}/${endDate}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${props.login.reportingToken}`,
          'Cache-Control': 'max-age=0, no-cache',
        },
      }
    )
      .then(res => res.blob())
      .then(res => {
        fileDownload(res, `setupad_${startDate}_${endDate}.xls`);
      })
      .catch(err => {
        alert('Error, try again.');
      });
  };

  return (
    <Container
      maxWidth="xl"
      style={{
        textAlign: 'left',
      }}
    >
      {isMobile && (
        <Grid container>
          <Grid item xs={12}>
            {props.login.menu && (
              <AccountsPropertiesSelection
                isMobile={isMobile}
                comparisonPeriod={comparisonPeriod}
                comparisonEnabled={comparisonEnabled}
                login={props.login}
                options={generateList()}
              />
            )}
          </Grid>
        </Grid>
      )}

      <FadeIn>
        <Grid container spacing={1} className={classes.containerWrapper}>
          <Grid item xs={isMobile ? 12 : 8}>
            {props.login.realtime === 1 && isMobile && (
              <div style={isMobile ? undefined : {float: 'right'}}>
                <Realtime isMobile={isMobile} />
              </div>
            )}

            {!isMobile && (
              <Typography variant={'h6'} style={{marginBottom: 10}}>
                Daily report
                <Tooltip
                  interactive
                  arrow
                  title={`Finalised daily data. Updated every morning at 6AM (GMT+3).`}
                >
                  <Info
                    style={{opacity: 0.5, left: 5, top: 4, position: 'relative'}}
                    fontSize={'small'}
                  />
                </Tooltip>
                {props.login.daily_setupad && <DataTypeSelector inDropdown={false} />}
              </Typography>
            )}
          </Grid>

          <Grid item xs={4}>
            {!isMobile && <TimezonePicker disabled={true} />}
          </Grid>

          <br />

          <Grid item xs={12}>
            <PeriodPicker isMobile={isMobile} />

            {!isMobile && (
              <>
                <FormControlLabel
                  style={{marginLeft: 10}}
                  labelPlacement={'end'}
                  control={
                    <Switch
                      disabled={!calculatedComparison}
                      color={'primary'}
                      checked={comparisonEnabled}
                      onChange={e => {
                        setComparisonEnabled(e.target.checked);
                        if (e.target.checked) {
                          lscache.set('comparisonEnabled', true);
                        } else {
                          lscache.remove('comparisonEnabled');
                        }
                        ReactGA.event({
                          category: 'comparison switch',
                          action: 'click',
                          label: e.target.checked ? 'enable' : 'disable',
                        });
                      }}
                    />
                  }
                  label={<Typography variant={'caption'}>Compare to previous period</Typography>}
                />
              </>
            )}

            {calculated && isMobile && activeMetrics && props.login && (
              <MetricsDisplay
                period={period}
                comparisonPeriod={comparisonPeriod}
                publisherStats={publisherStats}
                publisherStatsComparison={publisherStatsComparison}
                graphType={graphType}
                login={props.login}
                calculated={calculated}
                calculatedComparison={calculatedComparison}
                activeMetrics={activeMetrics}
                selection={selection}
                metricGroups={metricGroups}
                analyticsLoaded={analyticsLoaded}
                assignedGoogleProperties={props.assignedGoogleProperties}
                performanceEnabled={
                  props.login.refresh_enabled === 1 ||
                  props.login.refresh_enabled === 1 ||
                  props.login.viewability_enabled === 1 ||
                  props.login.rCPM_enabled === 1 ||
                  props.login.ctr_enabled === 1
                }
                handleGraphTypeChange={handleGraphTypeChange}
              />
            )}

            {calculated && !isMobile && (
              <>
                <Grid container alignItems={'center'} className={classes.statsWrapper}>
                  <Grid item xs={2}>
                    <Button
                      onClick={() => handleGraphTypeChange(1)}
                      size={'small'}
                      variant={
                        JSON.stringify(activeMetrics) === JSON.stringify(metricGroups[1])
                          ? 'contained'
                          : 'outlined'
                      }
                      color={
                        JSON.stringify(activeMetrics) === JSON.stringify(metricGroups[1])
                          ? 'primary'
                          : ''
                      }
                      className={classes.button}
                    >
                      Monetization
                    </Button>
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    style={{position: 'relative'}}
                    className={!activeMetrics.includes('Rev') ? classes.lowerOpacity : ''}
                    onClick={() => toggleMetric('Rev')}
                  >
                    <div className={classes.metricWrapper}>
                      <Tooltip
                        arrow
                        title={`Total ${publishersHasOwnGam ? 'gross' : 'net'} revenue.`}
                        placement="bottom"
                      >
                        <Typography variant={'caption'} color={'textSecondary'} gutterBottom>
                          Revenue
                          <Info
                            style={{opacity: 0.5, left: 5, top: 4, position: 'relative'}}
                            fontSize={'small'}
                          />
                        </Typography>
                      </Tooltip>

                      <Typography variant="h5">
                        {calculated.rev}€{/*{'' + millify(calculated.rev) + '€'}*/}
                        {calculatedComparison && (
                          <Trend
                            enabled={comparisonEnabled}
                            period={period}
                            comparisonPeriod={comparisonPeriod}
                            current={calculated.rev}
                            previous={calculatedComparison.rev}
                          />
                        )}
                      </Typography>
                    </div>
                    {activeMetrics.includes('Rev') && <div className={classes.revIndicator} />}
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    style={{position: 'relative'}}
                    className={!activeMetrics.includes('All Imp') ? classes.lowerOpacity : ''}
                    onClick={() => toggleMetric('All Imp')}
                  >
                    <div className={classes.metricWrapper}>
                      <Tooltip
                        arrow
                        title={'Number of ad server requests. Requests to start the auction.'}
                      >
                        <Typography variant={'caption'} color={'textSecondary'} gutterBottom>
                          Requests{' '}
                          <Info
                            style={{opacity: 0.5, left: 5, top: 4, position: 'relative'}}
                            fontSize={'small'}
                          />
                        </Typography>
                      </Tooltip>

                      <Typography variant="h5">
                        {'' + millify(calculated.imp)}
                        {calculatedComparison && (
                          <Trend
                            enabled={comparisonEnabled}
                            period={period}
                            comparisonPeriod={comparisonPeriod}
                            current={calculated.imp}
                            previous={calculatedComparison.imp}
                          />
                        )}
                      </Typography>
                    </div>
                    {activeMetrics.includes('All Imp') && (
                      <div className={classes.AllImpIndicitor} />
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    style={{position: 'relative'}}
                    className={!activeMetrics.includes('Paid Imp') ? classes.lowerOpacity : ''}
                    onClick={() => toggleMetric('Paid Imp')}
                  >
                    <div className={classes.metricWrapper}>
                      <Tooltip
                        arrow
                        title={
                          'The number of paid ad impressions delivered as a result of successful auctions.'
                        }
                      >
                        <Typography variant={'caption'} color={'textSecondary'} gutterBottom>
                          Impressions{' '}
                          <Info
                            style={{opacity: 0.5, left: 5, top: 4, position: 'relative'}}
                            fontSize={'small'}
                          />
                        </Typography>
                      </Tooltip>

                      <Typography variant="h5">
                        {millify(calculated.paidImp)}
                        {calculatedComparison && (
                          <Trend
                            enabled={comparisonEnabled}
                            period={period}
                            comparisonPeriod={comparisonPeriod}
                            current={calculated.paidImp}
                            previous={calculatedComparison.paidImp}
                          />
                        )}
                      </Typography>
                    </div>
                    {activeMetrics.includes('Paid Imp') && (
                      <div className={classes.PaidImpIndicitor} />
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    style={{position: 'relative'}}
                    className={!activeMetrics.includes('eCPM') ? classes.lowerOpacity : ''}
                    onClick={() => toggleMetric('eCPM')}
                  >
                    <div className={classes.metricWrapper}>
                      <Tooltip
                        arrow
                        interactive
                        title={
                          <React.Fragment>
                            Effective Cost Per Thousand Impressions." The average
                            {publishersHasOwnGam ? 'gross' : ''} revenue earned per 1000 ad
                            impressions.
                            <br />
                            <Link
                              href="https://setupad.com/blog/cpm-vs-ecpm-everything-publishers-should-know/"
                              target={'_blank'}
                              color="primary"
                            >
                              Read more
                            </Link>
                          </React.Fragment>
                        }
                      >
                        <Typography variant={'caption'} color={'textSecondary'} gutterBottom>
                          eCPM{' '}
                          <Info
                            style={{opacity: 0.5, left: 5, top: 4, position: 'relative'}}
                            fontSize={'small'}
                          />
                        </Typography>
                      </Tooltip>

                      <Typography variant="h5">
                        {calculated.rev != 0 && calculated.paidImp != 0
                          ? ((calculated.rev / calculated.paidImp) * 1000).toFixed(2)
                          : 0}
                        €
                        {calculatedComparison && (
                          <Trend
                            enabled={comparisonEnabled}
                            period={period}
                            comparisonPeriod={comparisonPeriod}
                            current={calculated.rev / calculated.paidImp}
                            previous={calculatedComparison.rev / calculatedComparison.paidImp}
                          />
                        )}
                      </Typography>
                    </div>

                    {activeMetrics.includes('eCPM') && <div className={classes.eCPMIndicator} />}
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    style={{position: 'relative'}}
                    className={!activeMetrics.includes('fillRate') ? classes.lowerOpacity : ''}
                    onClick={() => toggleMetric('fillRate')}
                  >
                    <div className={classes.metricWrapper}>
                      <Tooltip
                        arrow
                        interactive
                        title={
                          <React.Fragment>
                            The percentage of ad requests that resulted in paid ad impressions.
                            Calculated as (Impressions / Requests) * 100.
                            <br />
                            <Link
                              href="https://setupad.com/blog/ecpm-rcpm-and-fill-rate-everything-publishers-should-know/"
                              target={'_blank'}
                              color="primary"
                            >
                              Read more
                            </Link>
                          </React.Fragment>
                        }
                      >
                        <Typography variant={'caption'} color={'textSecondary'} gutterBottom>
                          Fill rate{' '}
                          <Info
                            style={{opacity: 0.5, left: 5, top: 4, position: 'relative'}}
                            fontSize={'small'}
                          />
                        </Typography>
                      </Tooltip>

                      <Typography variant="h5" component="h2">
                        {calculated.paidImp != 0 && calculated.imp != 0
                          ? ((calculated.paidImp * 100) / calculated.imp).toFixed(2)
                          : 0}
                        %
                        {calculatedComparison && calculated.paidImp != 0 && calculated.imp != 0 && (
                          <Trend
                            enabled={comparisonEnabled}
                            period={period}
                            comparisonPeriod={comparisonPeriod}
                            current={((calculated.paidImp * 100) / calculated.imp).toFixed(2)}
                            previous={(
                              (calculatedComparison.paidImp * 100) /
                              calculatedComparison.imp
                            ).toFixed(2)}
                          />
                        )}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>

                {(props.login.refresh_enabled === 1 ||
                  props.login.refresh_enabled === 1 ||
                  props.login.viewability_enabled === 1 ||
                  props.login.rCPM_enabled === 1 ||
                  props.login.ctr_enabled === 1) && (
                  <Grid container alignItems={'center'} className={classes.statsWrapper}>
                    <Grid item xs={2}>
                      <Button
                        onClick={() => handleGraphTypeChange(2)}
                        size={'small'}
                        variant={
                          JSON.stringify(activeMetrics) === JSON.stringify(metricGroups[2])
                            ? 'contained'
                            : 'outlined'
                        }
                        color={
                          JSON.stringify(activeMetrics) === JSON.stringify(metricGroups[2])
                            ? 'primary'
                            : ''
                        }
                        className={classes.button}
                      >
                        Performance
                      </Button>
                    </Grid>

                    {props.login.refresh_enabled === 1 && (
                      <Grid
                        item
                        xs={2}
                        style={{position: 'relative'}}
                        className={
                          !activeMetrics.includes('Rev Refresh') ? classes.lowerOpacity : ''
                        }
                        onClick={() => toggleMetric('Rev Refresh')}
                      >
                        <div className={classes.metricWrapper}>
                          <Tooltip
                            arrow
                            interactive
                            title={
                              <React.Fragment>
                                {publishersHasOwnGam ? 'Gross revenue' : 'Revenue'} from Viewable
                                Bid Optimization, an algorithm that refreshes ads only when
                                beneficial for publishers to maintain a high average eCPM.
                                <br />
                                <Link
                                  href="https://setupad.com/blog/ad-refresh-viewable-bid-optimization-begginers-guide/"
                                  target={'_blank'}
                                  color="primary"
                                >
                                  Read more
                                </Link>
                              </React.Fragment>
                            }
                          >
                            <Typography variant={'caption'} color={'textSecondary'} gutterBottom>
                              Refresh Revenue
                              <Info
                                style={{opacity: 0.5, left: 5, top: 4, position: 'relative'}}
                                fontSize={'small'}
                              />
                            </Typography>
                          </Tooltip>

                          <Typography variant="h5" component="h1">
                            {millify(calculated.refreshRev)}€
                            {calculatedComparison && (
                              <Trend
                                enabled={comparisonEnabled}
                                period={period}
                                comparisonPeriod={comparisonPeriod}
                                current={calculated.refreshRev}
                                previous={calculatedComparison.refreshRev}
                              />
                            )}
                          </Typography>
                        </div>
                        {activeMetrics.includes('Rev Refresh') && (
                          <div className={classes.revRefreshIndicator} />
                        )}
                      </Grid>
                    )}

                    {props.login.refresh_enabled === 1 && (
                      <Grid
                        item
                        xs={2}
                        style={{position: 'relative'}}
                        className={
                          !activeMetrics.includes('refreshRate') ? classes.lowerOpacity : ''
                        }
                        onClick={() => toggleMetric('refreshRate')}
                      >
                        <div className={classes.metricWrapper}>
                          <Tooltip
                            arrow
                            interactive
                            title={
                              <React.Fragment>
                                The rate at which ads are refreshed by the Viewable Bid Optimization
                                algorithm, ensuring refreshes occur only when beneficial to maintain
                                a high average eCPM.
                                <br />
                                <Link
                                  href="https://setupad.com/blog/ad-refresh-viewable-bid-optimization-begginers-guide/"
                                  target={'_blank'}
                                  color="primary"
                                >
                                  Read more
                                </Link>
                              </React.Fragment>
                            }
                          >
                            <Typography variant={'caption'} color={'textSecondary'} gutterBottom>
                              Smart Refresh Rate{' '}
                              <Info
                                style={{opacity: 0.5, left: 5, top: 4, position: 'relative'}}
                                fontSize={'small'}
                              />
                            </Typography>
                          </Tooltip>

                          <Typography variant="h5" component="h1">
                            {!isFinite(calculated.refreshRate) || isNaN(calculated.refreshRate)
                              ? 'N/A'
                              : `${calculated.refreshRate.toFixed(2)}%`}

                            {calculatedComparison &&
                              isFinite(
                                calculated.refreshRate && !isNaN(calculated.refreshRate)
                              ) && (
                                <Trend
                                  enabled={comparisonEnabled}
                                  period={period}
                                  comparisonPeriod={comparisonPeriod}
                                  current={calculated.refreshRate.toFixed(2)}
                                  previous={calculatedComparison.refreshRate.toFixed(2)}
                                />
                              )}
                          </Typography>
                        </div>
                        {activeMetrics.includes('refreshRate') && (
                          <div className={classes.refreshRateIndicator} />
                        )}
                      </Grid>
                    )}

                    {props.login.viewability_enabled === 1 && (
                      <Grid
                        item
                        xs={2}
                        style={{position: 'relative'}}
                        className={
                          !activeMetrics.includes('Viewability') ? classes.lowerOpacity : ''
                        }
                        onClick={() => toggleMetric('Viewability')}
                      >
                        <div className={classes.metricWrapper}>
                          <Tooltip
                            arrow
                            interactive
                            title={
                              <React.Fragment>
                                The percentage of ads that were visible in the browser for at least
                                1 continuous second and displayed at least 50% of the ad's pixels.
                                Measured by Google across Google Ad Exchange impressions.
                                <br />
                                <Link
                                  href="https://setupad.com/blog/why-ad-viewability-important-digital-advertising/"
                                  target={'_blank'}
                                  color="primary"
                                >
                                  Read more
                                </Link>
                              </React.Fragment>
                            }
                          >
                            <Typography variant={'caption'} color={'textSecondary'} gutterBottom>
                              Viewability{' '}
                              <Info
                                style={{opacity: 0.5, left: 5, top: 4, position: 'relative'}}
                                fontSize={'small'}
                              />
                            </Typography>
                          </Tooltip>

                          <Typography variant="h5" component="h2">
                            {isNaN(calculated.viewability) ? 'N/A' : `${calculated.viewability}%`}

                            {calculatedComparison && !isNaN(calculated.viewability) && (
                              <Trend
                                enabled={comparisonEnabled}
                                period={period}
                                comparisonPeriod={comparisonPeriod}
                                current={calculated.viewability}
                                previous={calculatedComparison.viewability}
                              />
                            )}
                          </Typography>
                        </div>
                        {activeMetrics.includes('Viewability') && (
                          <div className={classes.viewabilityIndicitor} />
                        )}
                      </Grid>
                    )}

                    {props.login.rCPM_enabled === 1 && (
                      <Grid
                        item
                        xs={2}
                        style={{position: 'relative'}}
                        className={!activeMetrics.includes('rCPM') ? classes.lowerOpacity : ''}
                        onClick={() => toggleMetric('rCPM')}
                      >
                        <div className={classes.metricWrapper}>
                          <Tooltip
                            arrow
                            interactive
                            title={
                              <React.Fragment>
                                "Request CPM" or "Revenue CPM." The average{' '}
                                {publishersHasOwnGam ? 'gross' : ''} revenue per 1000 ad requests,
                                including those not filled. The difference between rCPM and eCPM is
                                equivalent to fill rate.
                                <br />
                                <Link
                                  href="https://setupad.com/blog/ecpm-rcpm-and-fill-rate-everything-publishers-should-know/"
                                  target={'_blank'}
                                  color="primary"
                                >
                                  Read more
                                </Link>
                              </React.Fragment>
                            }
                          >
                            <Typography variant={'caption'} color={'textSecondary'} gutterBottom>
                              rCPM{' '}
                              <Info
                                style={{opacity: 0.5, left: 5, top: 4, position: 'relative'}}
                                fontSize={'small'}
                              />
                            </Typography>
                          </Tooltip>

                          <Typography
                            variant="h5"
                            component="h2"
                            //style={{color: "#e75d3c"}}
                          >
                            {calculated.rev != 0 && calculated.imp != 0
                              ? ((calculated.rev / calculated.imp) * 1000).toFixed(2)
                              : 0}
                            €
                            {calculatedComparison && (
                              <Trend
                                enabled={comparisonEnabled}
                                period={period}
                                comparisonPeriod={comparisonPeriod}
                                current={calculated.rev / calculated.imp}
                                previous={calculatedComparison.rev / calculatedComparison.imp}
                              />
                            )}
                          </Typography>
                        </div>
                        {activeMetrics.includes('rCPM') && (
                          <div className={classes.RCPMIndicator} />
                        )}
                      </Grid>
                    )}

                    {props.login.ctr_enabled === 1 && (
                      <Grid
                        item
                        xs={2}
                        style={{position: 'relative'}}
                        className={!activeMetrics.includes('CTR') ? classes.lowerOpacity : ''}
                        onClick={() => toggleMetric('CTR')}
                      >
                        <div className={classes.metricWrapper}>
                          <Tooltip
                            arrow
                            title={
                              'The percentage of ad impressions that resulted in a user click. Measured by Google across Google Ad Exchange impressions.'
                            }
                          >
                            <Typography variant={'caption'} color={'textSecondary'} gutterBottom>
                              CTR{' '}
                              <Info
                                style={{opacity: 0.5, left: 5, top: 4, position: 'relative'}}
                                fontSize={'small'}
                              />
                            </Typography>
                          </Tooltip>
                          <Typography variant="h5" component="h2">
                            {isNaN(calculated.CTR) ? 'N/A' : `${calculated.CTR}%`}

                            {calculatedComparison && !isNaN(calculated.CTR) && (
                              <Trend
                                enabled={comparisonEnabled}
                                period={period}
                                comparisonPeriod={comparisonPeriod}
                                current={calculated.CTR}
                                previous={calculatedComparison.CTR}
                              />
                            )}
                          </Typography>
                        </div>
                        {activeMetrics.includes('CTR') && <div className={classes.CTRIndicator} />}
                      </Grid>
                    )}
                  </Grid>
                )}

                {props.assignedGoogleProperties !== 'error' &&
                  (props.assignedGoogleProperties.find(
                    property => property.domainId == selection.value.domain
                  ) ||
                    (Object.keys(props.assignedGoogleProperties).length > 0 &&
                      !selection.value.publisher)) &&
                  !analyticsError && (
                    <>
                      {!analyticsLoaded && (
                        <Grid container alignItems={'center'} className={classes.statsWrapper}>
                          <Grid item xs={2}>
                            <Button
                              size={'small'}
                              variant={
                                JSON.stringify(activeMetrics) === JSON.stringify(metricGroups[3])
                                  ? 'contained'
                                  : 'outlined'
                              }
                              color={
                                JSON.stringify(activeMetrics) === JSON.stringify(metricGroups[3])
                                  ? 'primary'
                                  : ''
                              }
                              className={classes.button}
                            >
                              Audience
                              <img
                                src={AnalyticsIcon}
                                style={{position: 'relative', top: 0, right: -3, height: 13}}
                              />
                            </Button>
                          </Grid>
                          <Grid item xs={2}>
                            <Skeleton width={'90%'} height={80} />
                          </Grid>
                          <Grid item xs={2}>
                            <Skeleton width={'90%'} height={80} />
                          </Grid>
                          <Grid item xs={2}>
                            <Skeleton width={'90%'} height={80} />
                          </Grid>
                          <Grid item xs={2}>
                            <Skeleton width={'90%'} height={80} />
                          </Grid>
                          <Grid item xs={2}>
                            <Skeleton width={'90%'} height={80} />
                          </Grid>
                        </Grid>
                      )}

                      {analyticsLoaded && (
                        <Grid container alignItems={'center'} className={classes.statsWrapper}>
                          <Grid item xs={2}>
                            <Button
                              onClick={() =>
                                selection.value.placement ? '' : handleGraphTypeChange(3)
                              }
                              size={'small'}
                              variant={
                                JSON.stringify(activeMetrics) === JSON.stringify(metricGroups[3])
                                  ? 'contained'
                                  : 'outlined'
                              }
                              color={
                                JSON.stringify(activeMetrics) === JSON.stringify(metricGroups[3])
                                  ? 'primary'
                                  : ''
                              }
                              className={classes.button}
                            >
                              Audience
                              <img
                                src={AnalyticsIcon}
                                style={{position: 'relative', top: 0, right: -3, height: 13}}
                              />
                            </Button>
                          </Grid>

                          {selection.value.placement && (
                            <Grid item xs={10}>
                              <Typography align={'center'} variant={'body'} color={'textSecondary'}>
                                Available only on a Domain and Account Total levels
                              </Typography>
                            </Grid>
                          )}
                          {!selection.value.placement && (
                            <>
                              <Grid
                                item
                                xs={2}
                                style={{position: 'relative'}}
                                className={
                                  !activeMetrics.includes('uRPM') ||
                                  !props.assignedGoogleProperties.find(
                                    property => property.domainId == selection.value.domain
                                  )
                                    ? classes.lowerOpacity
                                    : ''
                                }
                                onClick={() => toggleMetric('uRPM')}
                              >
                                <div className={classes.metricWrapperAudience}>
                                  <Tooltip
                                    arrow
                                    title={
                                      "Revenue per 1000 users. The number of users is obtained from Google Analytics data. uRPM is available on domain level only since it's non-cumulative statistics."
                                    }
                                    placement="bottom"
                                  >
                                    <Typography
                                      variant={'caption'}
                                      color={'textSecondary'}
                                      gutterBottom
                                    >
                                      {/*<img src={AnalyticsIcon} style={{paddingRight: 10}}/>*/}
                                      uRPM{' '}
                                      <Info
                                        style={{
                                          opacity: 0.5,
                                          left: 5,
                                          top: 4,
                                          position: 'relative',
                                        }}
                                        fontSize={'small'}
                                      />
                                    </Typography>
                                  </Tooltip>

                                  <Typography variant="h5">
                                    {props.assignedGoogleProperties.find(
                                      property => property.domainId == selection.value.domain
                                    )
                                      ? `${(calculated.rev / (calculated.users / 1000)).toFixed(
                                          2
                                        )}€`
                                      : `ND`}

                                    {calculatedComparison &&
                                      analyticsComparisonLoaded &&
                                      props.assignedGoogleProperties.find(
                                        property => property.domainId == selection.value.domain
                                      ) && (
                                        <Trend
                                          enabled={comparisonEnabled}
                                          period={period}
                                          comparisonPeriod={comparisonPeriod}
                                          current={calculated.rev / (calculated.users / 1000)}
                                          previous={
                                            calculatedComparison.rev /
                                            (calculatedComparison.users / 1000)
                                          }
                                        />
                                      )}

                                    <Typography
                                      variant={'caption'}
                                      style={{lineHeight: '0.5rem'}}
                                      color={'textPrimary'}
                                      display={'block'}
                                    >
                                      {props.assignedGoogleProperties.find(
                                        property => property.domainId == selection.value.domain
                                      )
                                        ? `(${millify(calculated.users)} Users)`
                                        : `(non-cumulative data)`}
                                    </Typography>
                                  </Typography>
                                </div>

                                {activeMetrics.includes('uRPM') &&
                                  props.assignedGoogleProperties.find(
                                    property => property.domainId == selection.value.domain
                                  ) && <div className={classes.uRPMIndicator} />}
                              </Grid>

                              <Grid
                                item
                                xs={2}
                                style={{position: 'relative'}}
                                className={
                                  !activeMetrics.includes('sRPM') ? classes.lowerOpacity : ''
                                }
                                onClick={() => toggleMetric('sRPM')}
                              >
                                <div className={classes.metricWrapperAudience}>
                                  <Tooltip
                                    arrow
                                    title={`Revenue per 1000 sessions. The number of sessions is obtained from connected Google Analytics data. sRPM is available on domain and account levels.`}
                                    placement="bottom"
                                  >
                                    <Typography
                                      variant={'caption'}
                                      color={'textSecondary'}
                                      gutterBottom
                                    >
                                      {/*<img src={AnalyticsIcon} style={{paddingRight: 10}}/>*/}
                                      sRPM{' '}
                                      <Info
                                        style={{
                                          opacity: 0.5,
                                          left: 5,
                                          top: 4,
                                          position: 'relative',
                                        }}
                                        fontSize={'small'}
                                      />
                                    </Typography>
                                  </Tooltip>

                                  <Typography variant="h5">
                                    {(calculated.rev / (calculated.visits / 1000)).toFixed(2)}€
                                    {calculatedComparison && analyticsComparisonLoaded && (
                                      <Trend
                                        enabled={comparisonEnabled}
                                        period={period}
                                        comparisonPeriod={comparisonPeriod}
                                        current={calculated.rev / (calculated.visits / 1000)}
                                        previous={
                                          calculatedComparison.rev /
                                          (calculatedComparison.visits / 1000)
                                        }
                                      />
                                    )}
                                    <Typography
                                      variant={'caption'}
                                      style={{lineHeight: '0.5rem'}}
                                      color={'textPrimary'}
                                      display={'block'}
                                    >
                                      ({millify(calculated.visits)} Sessions)
                                    </Typography>
                                  </Typography>
                                </div>
                                {activeMetrics.includes('sRPM') && (
                                  <div className={classes.sRPMIndicator} />
                                )}
                              </Grid>

                              <Grid
                                item
                                xs={2}
                                style={{position: 'relative'}}
                                className={
                                  !activeMetrics.includes('pvRPM') ? classes.lowerOpacity : ''
                                }
                                onClick={() => toggleMetric('pvRPM')}
                              >
                                <div className={classes.metricWrapperAudience}>
                                  <Tooltip
                                    arrow
                                    title={`Revenue per 1000 pageviews. The number of pageviews is obtained from connected Google Analytics data. pvRPM is available on domain and account levels.`}
                                    placement="bottom"
                                  >
                                    <Typography
                                      variant={'caption'}
                                      color={'textSecondary'}
                                      gutterBottom
                                    >
                                      pvRPM{' '}
                                      <Info
                                        style={{
                                          opacity: 0.5,
                                          left: 5,
                                          top: 4,
                                          position: 'relative',
                                        }}
                                        fontSize={'small'}
                                      />
                                    </Typography>
                                  </Tooltip>

                                  <Typography variant="h5">
                                    {(calculated.rev / (calculated.pageviews / 1000)).toFixed(2)}€
                                    {calculatedComparison && analyticsComparisonLoaded && (
                                      <Trend
                                        enabled={comparisonEnabled}
                                        period={period}
                                        comparisonPeriod={comparisonPeriod}
                                        current={calculated.rev / (calculated.pageviews / 1000)}
                                        previous={
                                          calculatedComparison.rev /
                                          (calculatedComparison.pageviews / 1000)
                                        }
                                      />
                                    )}
                                    <Typography
                                      variant={'caption'}
                                      style={{lineHeight: '0.5rem'}}
                                      color={'textPrimary'}
                                      display={'block'}
                                    >
                                      ({millify(calculated.pageviews)} Pageviews)
                                    </Typography>
                                  </Typography>
                                </div>
                                {activeMetrics.includes('pvRPM') && (
                                  <div className={classes.pvRPMIndicator} />
                                )}

                                {/*<Typography variant={"caption"} color={"textSecondary"}>*/}
                                {/*{props.assignedGoogleProperties.find(property => property.domainId == selection.value.domain).property.label}*/}
                                {/*</Typography>*/}
                              </Grid>

                              <Grid
                                item
                                xs={2}
                                style={{position: 'relative'}}
                                className={
                                  !activeMetrics.includes('sessionsPerUser') ||
                                  !props.assignedGoogleProperties.find(
                                    property => property.domainId == selection.value.domain
                                  )
                                    ? classes.lowerOpacity
                                    : ''
                                }
                              >
                                <div className={classes.metricWrapperAudience}>
                                  <Tooltip
                                    arrow
                                    title={`Number of sessions per user. Average number of sessions made by users. Obtained from connected Google Analytics data. Available on domain level only since it's non-cumulative statistics. `}
                                    placement="bottom"
                                  >
                                    <Typography
                                      variant={'caption'}
                                      color={'textSecondary'}
                                      gutterBottom
                                    >
                                      Sessions / User{' '}
                                      <Info
                                        style={{
                                          opacity: 0.5,
                                          left: 5,
                                          top: 4,
                                          position: 'relative',
                                        }}
                                        fontSize={'small'}
                                      />
                                    </Typography>
                                  </Tooltip>

                                  <Typography variant="h5">
                                    {props.assignedGoogleProperties.find(
                                      property => property.domainId == selection.value.domain
                                    )
                                      ? millify(calculated.sessionsPerUser)
                                      : `ND`}

                                    {calculatedComparison &&
                                      props.assignedGoogleProperties.find(
                                        property => property.domainId == selection.value.domain
                                      ) && (
                                        <Trend
                                          enabled={comparisonEnabled}
                                          period={period}
                                          comparisonPeriod={comparisonPeriod}
                                          current={calculated.sessionsPerUser}
                                          previous={calculatedComparison.sessionsPerUser}
                                        />
                                      )}
                                  </Typography>

                                  <Typography
                                    variant={'caption'}
                                    style={{lineHeight: '0.5rem'}}
                                    color={'textPrimary'}
                                    display={'block'}
                                  >
                                    {props.assignedGoogleProperties.find(
                                      property => property.domainId == selection.value.domain
                                    )
                                      ? ``
                                      : `(non-cumulative data)`}
                                  </Typography>
                                </div>
                              </Grid>

                              <Grid
                                item
                                xs={2}
                                style={{position: 'relative'}}
                                className={
                                  !activeMetrics.includes('pageviewsPerSession')
                                    ? classes.lowerOpacity
                                    : ''
                                }
                              >
                                <div className={classes.metricWrapperAudience}>
                                  <Tooltip
                                    arrow
                                    title={`The number of pages per session. The average number of pages viewed during a session. Obtained from connected Google Analytics data. Available on domain and account levels.`}
                                    placement="bottom"
                                  >
                                    <Typography
                                      variant={'caption'}
                                      color={'textSecondary'}
                                      gutterBottom
                                    >
                                      {/*<img src={AnalyticsIcon} style={{paddingRight: 10}}/>*/}
                                      Pages / Session{' '}
                                      <Info
                                        style={{
                                          opacity: 0.5,
                                          left: 5,
                                          top: 4,
                                          position: 'relative',
                                        }}
                                        fontSize={'small'}
                                      />
                                    </Typography>
                                  </Tooltip>

                                  <Typography variant="h5">
                                    {calculated.pageviewsPerSession
                                      ? millify(calculated.pageviewsPerSession)
                                      : 0}

                                    {calculatedComparison && analyticsComparisonLoaded && (
                                      <Trend
                                        enabled={comparisonEnabled}
                                        period={period}
                                        comparisonPeriod={comparisonPeriod}
                                        current={calculated.pageviewsPerSession}
                                        previous={calculatedComparison.pageviewsPerSession}
                                      />
                                    )}
                                  </Typography>
                                </div>
                              </Grid>
                            </>
                          )}
                        </Grid>
                      )}
                    </>
                  )}

                {!isMobile && (
                  <Grid container direction="row-reverse">
                    <Grid item xs={12}>
                      <Button
                        size={'small'}
                        variant={
                          JSON.stringify(activeMetrics) === JSON.stringify(metricGroups[4])
                            ? 'contained'
                            : 'outlined'
                        }
                        color={
                          JSON.stringify(activeMetrics) === JSON.stringify(metricGroups[4])
                            ? 'primary'
                            : ''
                        }
                        style={{marginTop: 20}}
                        className={classes.button}
                        onClick={() => handleGraphTypeChange(4)}
                      >
                        All metrics
                      </Button>

                      {props.login.username !== 'demo' && (
                        <Button
                          size={'small'}
                          disabled={dataType !== 'combined'}
                          variant={'outlined'}
                          style={{marginTop: 20}}
                          className={classes.button}
                          endIcon={<VerticalAlignBottom />}
                          onClick={exportXLS}
                        >
                          Export to XLS
                        </Button>
                      )}

                      {props.login.username !== 'demo' && (
                        <ShareDialog
                          publisherStats={publisherStats}
                          activeMetrics={activeMetrics}
                          calculated={calculated}
                        />
                      )}
                    </Grid>
                  </Grid>
                )}
              </>
            )}

            {((!publisherStats && !calculated) || (publisherStats.length !== 0 && !calculated)) &&
              !isMobile &&
              !analyticsError && (
                <div style={{width: '100%', textAlign: 'center'}}>
                  <Skeleton
                    variant="rect"
                    width={'100%'}
                    height={50}
                    animation="wave"
                    style={{marginBottom: 10, marginTop: 10}}
                  />
                  {(props.login.refresh_enabled === 1 ||
                    props.login.refresh_enabled === 1 ||
                    props.login.viewability_enabled === 1 ||
                    props.login.rCPM_enabled === 1 ||
                    props.login.ctr_enabled === 1) && (
                    <Skeleton
                      variant="rect"
                      width={'100%'}
                      height={50}
                      animation="wave"
                      style={{marginTop: 15}}
                    />
                  )}
                  {props.assignedGoogleProperties !== 'error' &&
                    (props.assignedGoogleProperties.find(
                      property => property.domainId == selection.value.domain
                    ) ||
                      Object.keys(props.assignedGoogleProperties).length > 0) &&
                    !analyticsError && (
                      <Skeleton
                        variant="rect"
                        width={'100%'}
                        height={70}
                        animation="wave"
                        style={{marginTop: 15}}
                      />
                    )}
                  <Skeleton
                    variant="rect"
                    width={'20%'}
                    height={30}
                    animation="wave"
                    style={{marginTop: 25}}
                  />
                  <Skeleton
                    variant="rect"
                    width={'100%'}
                    height={420}
                    animation="wave"
                    style={{marginTop: 20}}
                  />
                </div>
              )}

            {((!publisherStats && !calculated) || (publisherStats.length !== 0 && !calculated)) &&
              isMobile && (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    marginTop: theme.spacing(2.5),
                    marginBottom: theme.spacing(2),
                  }}
                >
                  <Skeleton
                    variant="rect"
                    width={'100%'}
                    height={33}
                    animation="wave"
                    style={{marginBottom: 16, borderRadius: 4}}
                  />
                  <Skeleton
                    variant="rect"
                    width={'100%'}
                    height={72}
                    animation="wave"
                    style={{marginBottom: 8, borderRadius: 4}}
                  />
                  <Skeleton
                    variant="rect"
                    width={'100%'}
                    height={72}
                    animation="wave"
                    style={{marginBottom: 8, borderRadius: 4}}
                  />
                  <Skeleton
                    variant="rect"
                    width={'100%'}
                    height={72}
                    animation="wave"
                    style={{marginBottom: 8, borderRadius: 4}}
                  />
                  <Skeleton
                    variant="rect"
                    width={'100%'}
                    height={72}
                    animation="wave"
                    style={{marginBottom: 8, borderRadius: 4}}
                  />
                  <Skeleton
                    variant="rect"
                    width={'100%'}
                    height={72}
                    animation="wave"
                    style={{marginBottom: 8, borderRadius: 4}}
                  />
                </div>
              )}

            {!analyticsReminderHidden &&
              props.login.google_analytics_enabled === 1 &&
              props.assignedGoogleProperties.length === 0 &&
              selection.value.domain &&
              !lscache.get('hideAnalyticsRemined') && (
                <Alert
                  severity="warning"
                  action={
                    <>
                      <Button
                        component={Link}
                        href={'/google-analytics'}
                        variant={'contained'}
                        color={'primary'}
                        size={'small'}
                        startIcon={<img src={AnalyticsIcon} style={{paddingRight: 10}} />}
                      >
                        Configuration
                      </Button>
                      <IconButton
                        size={'small'}
                        onClick={() => {
                          lscache.set('hideAnalyticsRemined', true);
                          setAnalyticsReminderHidden(true);
                        }}
                      >
                        <Close />
                      </IconButton>
                    </>
                  }
                >
                  Did you know that you can now connect your Google Analytics and view more
                  insightful metrics for the monetized domains?
                </Alert>
              )}

            {analyticsError && (
              <Alert
                severity="warning"
                action={
                  <Button
                    component={Link}
                    href={'/google-analytics'}
                    variant={'contained'}
                    color={'primary'}
                    size={'small'}
                    startIcon={<img src={AnalyticsIcon} style={{paddingRight: 10}} />}
                  >
                    Go to configuration
                  </Button>
                }
              >
                Google Analytics error: {analyticsError}
              </Alert>
            )}

            {publisherStats.length === 0 && (
              <Container
                maxWidth="xl"
                style={{
                  padding: 65,
                  textAlign: 'center',
                }}
              >
                <Placeholder />
                <Typography align={'center'}>No data</Typography>
              </Container>
            )}

            <HistoricalDataAlert publisherStats={publisherStats} />

            {/*AccountsDetailsTable is rendered twice because it doesn't update publisherStats in internal state when provided, so redrawing whole graph for now*/}
            {publisherStats && publisherStats.length !== 0 && (
              <>
                {!isMobile && publisherStats && calculated && !analyticsLoaded && (
                  <AccountsDetailsTable
                    domainHistory={domainHistory}
                    activeMetrics={activeMetrics}
                    analyticsAdded={
                      (!analyticsError &&
                        props.assignedGoogleProperties !== 'error' &&
                        props.assignedGoogleProperties.find(
                          property => property.domainId == selection.value.domain
                        )) ||
                      Object.keys(props.assignedGoogleProperties).length > 0
                        ? analyticsLoaded
                          ? true
                          : false
                        : false
                    }
                    analyticsProperty={props.assignedGoogleProperties.find(
                      property => property.domainId == selection.value.domain
                    )}
                    publisherStats={publisherStats}
                    chartRef={chartRef}
                    handleZoom={handleZoom}
                    login={props.login}
                  />
                )}

                {!isMobile && publisherStats && calculated && analyticsLoaded && (
                  <AccountsDetailsTable
                    domainHistory={domainHistory}
                    activeMetrics={activeMetrics}
                    analyticsAdded={
                      (!analyticsError &&
                        props.assignedGoogleProperties !== 'error' &&
                        props.assignedGoogleProperties.find(
                          property => property.domainId == selection.value.domain
                        )) ||
                      Object.keys(props.assignedGoogleProperties).length > 0
                        ? analyticsLoaded
                          ? true
                          : false
                        : false
                    }
                    analyticsProperty={props.assignedGoogleProperties.find(
                      property => property.domainId == selection.value.domain
                    )}
                    publisherStats={publisherStats}
                    chartRef={chartRef}
                    handleZoom={handleZoom}
                    login={props.login}
                  />
                )}

                {!selection.label?.includes('interstitial') &&
                  calculated &&
                  props.login.ssp_table_enabled === 1 &&
                  isMobile && (
                    <DecilesView
                      totalImpressions={parseFloat(calculated.paidImp)}
                      totalRevenue={parseFloat(calculated.rev)}
                      login={props.login}
                      eCPM={((calculated.rev / calculated.paidImp) * 1000).toFixed(2)}
                      selection={selection}
                      menu={props.login.menu}
                      period={period}
                    />
                  )}
              </>
            )}
          </Grid>
        </Grid>
      </FadeIn>
    </Container>
  );
});

export default React.memo(AccountDetails);
