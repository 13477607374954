import React from 'react';
import {Link, Grid, Typography, Divider, IconButton, Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles/index';
import FadeIn from 'react-fade-in/lib/FadeIn';
import moment from 'moment';
import lscache from 'lscache';

const config = {
  url: 'https://www.capterra.com/p/209531/Setupad/reviews/',
  imageUrl: 'https://brand-assets.capterra.com/badge/c6d3b665-e95f-4c0a-bbd9-766999d96869.svg',
  backgroundImageUrl:
    'https://setupad.com/wp-content/themes/setupad/assets/img/landing/hybrid-header-bidding.svg?v=1',
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: theme.spacing(3),
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
    padding: theme.spacing(1),
    borderRadius: 8,
    width: 'auto',
    cursor: 'pointer',
    backgroundImage: `url(${config.backgroundImageUrl})`,
    backgroundSize: '86%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom center',
    minHeight: 285,
    position: 'relative',
    border: `1px dashed ${theme.palette.graphColors.historicalBackground}`,
  },
  logo: {
    width: '96%',
    margin: '0 auto',
    display: 'block',
  },
  close: {
    marginBottom: theme.spacing(1),
    margin: '0 auto',
    display: 'block',
  },
}));

// Should be shown to users who are at least 3 months old and are the following types: Sutupad Wrapper (1), Setupad Prebid (4), Self Serve Enterprise (7)
const allowedAccountTypes = [1, 4, 7];
const minimumAccountAge = {
  timeFrame: 'months',
  value: 3,
};

const ReviewUsBlock = ({auth}) => {
  const classes = useStyles();
  const lsKey = `ReviewUsBlockClosed_${auth.accountId}`;
  let favorsRules =
    allowedAccountTypes.includes(auth.type) &&
    !lscache.get(lsKey) &&
    moment().diff(auth.creationDate, minimumAccountAge.timeFrame) >= minimumAccountAge.value;

  const [visible, setVisible] = React.useState(favorsRules);

  const handleClose = () => {
    setVisible(false);
    lscache.set(lsKey, true, false);
  };

  // alert(auth.type);

  if (visible)
    return (
      <FadeIn>
        <Grid
          container
          spacing={2}
          className={classes.wrapper}
          alignItems="center"
          direction="column"
        >
          <Link href={config.url} target={'_blank'}>
            <Grid item xs={12}>
              <Typography gutterBottom color={'textPrimary'} align={'center'} display={'block'}>
                Review us on Capterra
              </Typography>
              <FadeIn delay={400}>
                <img src={config.imageUrl} className={classes.logo} />
              </FadeIn>
            </Grid>
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Button variant={'text'} onClick={handleClose} className={classes.close}>
            <Typography variant={'caption'} color={'textSecondary'}>
              Close
            </Typography>
          </Button>
        </Grid>
        <Divider />
      </FadeIn>
    );

  return null;
};

export default ReviewUsBlock;
