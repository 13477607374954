import React, {useEffect, useRef, useCallback} from 'react';
import {makeStyles} from '@material-ui/core/styles/index';
import ReactGA from 'react-ga4';

import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FeedbackIcon from '@material-ui/icons/Feedback';
import RateReviewIcon from '@material-ui/icons/RateReview';
import {APICall} from '../../api/api';
import {useMediaQuery} from 'react-responsive';
import breakpoints from '../helpers/breakpoints';
import useStore from '../../store/store';

const useStyles = makeStyles(theme => ({
  selectedEmoji: {
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(0.7),
    margin: theme.spacing(0.3),
  },
  emoji: {
    border: '1px solid rgba(0,0,0,0)',
    padding: theme.spacing(0.7),
    margin: theme.spacing(0.3),
  },
}));

const Feedback = props => {
  const classes = useStyles();
  const [feedbackText, setFeedbackText] = React.useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [status, setStatus] = React.useState(null);
  const [rating, setRating] = React.useState(null);
  const isMobile = useMediaQuery({query: breakpoints.mobile});
  const setState = useStore(state => state.setState);
  const disableTypingForAccountSelector = useStore(state => state.disableTypingForAccountSelector);

  const handleClick = event => {
    ReactGA.event({
      category: 'Feedback form',
      action: 'click',
      label: 'open',
    });
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    ReactGA.event({
      category: 'Feedback form',
      action: 'click',
      label: 'close',
    });
    setAnchorEl(null);
  };

  useEffect(() => {
    setState('disableTypingForAccountSelector', anchorEl ? true : false);
  }, [anchorEl]);

  const handleChange = event => {
    setStatus(null);
    setFeedbackText(event.target.value);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const submitFeedback = async () => {
    ReactGA.event({
      category: 'Feedback form',
      action: 'click',
      label: 'submit',
    });

    let query = await APICall({
      endpoint: 'feedback',
      options: {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${props.login.token}`,
          'Cache-Control': 'max-age=0, no-cache',
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          feedbackText: feedbackText,
          pathname: window.location.pathname,
          rating: rating,
        }),
      },
    });

    if (query.success) {
      setStatus('sent');
    } else {
      setStatus('error');
    }
  };

  return (
    <>
      {!isMobile && (
        <Tooltip title={`Share any feedback about Setupad Client UI`}>
          <Button
            disabled={props.login.username === 'demo'}
            aria-describedby={id}
            onClick={handleClick}
            variant="outlined"
            size={'small'}
            startIcon={<RateReviewIcon />}
            style={{cursor: 'text', marginLeft: 25}}
          >
            Feedback
          </Button>
        </Tooltip>
      )}

      {isMobile && (
        <Tooltip title={`Share any feedback about Setupad Client UI`}>
          <IconButton onClick={handleClick}>
            <FeedbackIcon />
          </IconButton>
        </Tooltip>
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={9}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box p={2} pb={1}>
          <TextField
            onChange={handleChange}
            value={feedbackText}
            autoFocus
            margin={'dense'}
            label={'Feedback'}
            variant={'outlined'}
            multiline
            rows={5}
            size={'small'}
            disabled={status === 'sent'}
          />
        </Box>
        <Box alignItems="flex-end" justifyContent={'space-between'} display="flex" p={2} pt={0}>
          <IconButton
            className={rating === 0 ? classes.selectedEmoji : classes.emoji}
            size={'small'}
            onClick={() => setRating(0)}
          >
            😭
          </IconButton>

          <IconButton
            className={rating === 1 ? classes.selectedEmoji : classes.emoji}
            size={'small'}
            onClick={() => setRating(1)}
          >
            😟
          </IconButton>

          <IconButton
            className={rating === 2 ? classes.selectedEmoji : classes.emoji}
            size={'small'}
            onClick={() => setRating(2)}
          >
            🙂
          </IconButton>

          <IconButton
            className={rating === 3 ? classes.selectedEmoji : classes.emoji}
            size={'small'}
            onClick={() => setRating(3)}
          >
            🤩
          </IconButton>

          <Button
            disabled={status === 'sent'}
            variant={'contained'}
            color={'primary'}
            display={'block'}
            onClick={submitFeedback}
          >
            {status === 'sent' ? 'Sent!' : 'Send'}
          </Button>
        </Box>
        {status === 'error' && (
          <Box justifyContent={'center'} display="flex" p={2} pt={0}>
            <Typography variant={'caption'}>Error, please try again.</Typography>
          </Box>
        )}
      </Popover>
    </>
  );
};

export default Feedback;
