import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Logo from './../img/setupad_client_final.svg';
import Link from '@material-ui/core/Link';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import {useTheme} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {APICall} from '../../api/api';
import useStore from '../../store/store';
import getTermsAndConditionsFileUrl from '../misc/getTermsAndConditionsFileUrl';

const PolicyDialog = ({themeStyle, login}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const auth = useStore(state => state.auth);
  const visiblePopup = useStore(state => state.visiblePopup);
  const setState = useStore(state => state.setState);
  const [missingCheckbox, setMissingCheckbox] = React.useState(false);
  const isSelfServeAccount = auth.type >= 5 && auth?.type < 8;
  const policyFileUrl = getTermsAndConditionsFileUrl(auth);

  const [checkboxes, setCheckboxes] = React.useState({
    first: false,
    second: false,
    third: false,
  });

  const handleChange = event => {
    setCheckboxes({...checkboxes, [event.target.name]: event.target.checked});
  };

  const acceptTOS = async () => {
    let missingCheckboxes = Object.entries(checkboxes).filter(checkbox => checkbox[1] === false);

    if (missingCheckboxes.length) {
      setMissingCheckbox(checkboxes);
      return;
    } else {
      setMissingCheckbox(false);

      await APICall({
        endpoint: 'acceptTos',
        options: {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${login.token}`,
            'Cache-Control': 'max-age=0, no-cache',
          },
        },
      });

      // Mark in auth that user has accepted TOS
      setState('auth', {...auth, standard_tos_accepted: 1});
    }
  };

  return (
    <>
      <Dialog fullScreen={fullScreen} open={visiblePopup === 'policy'}>
        <DialogTitle>
          <img
            alt={'Setupad logo'}
            src={
              themeStyle === 'light'
                ? Logo
                : 'https://setupad.com/wp-content/themes/setupad/assets/img/new_company_logo.svg'
            }
            style={{height: '21px', top: 3, position: 'relative'}}
          />
          <Typography variant={'h6'}>Terms and Conditions</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please read the Terms and Conditions carefully before registering for{' '}
            {isSelfServeAccount ? 'Setupad Prebid Self-Serve' : 'Setupad Client UI'}. By using the
            Setupad Client UI, you confirm that you accept and comply with the Terms and Conditions.
            If you do not agree with our Terms and Conditions, please do not register or use the
            Setupad Client UI.
          </DialogContentText>
          <DialogContentText>
            Setupad <strong>Terms and Conditions</strong> is available{' '}
            <Link color={'primary'} variant={'text'} target={'_blank'} href={policyFileUrl}>
              here
            </Link>
            .
          </DialogContentText>
          <DialogContentText>
            Setupad <strong>Privacy Policy</strong> is available{' '}
            <Link
              color={'primary'}
              variant={'text'}
              target={'_blank'}
              href={'https://setupad.com/privacy-policy/'}
            >
              here
            </Link>
            .
          </DialogContentText>
          <DialogContentText>
            <FormGroup row>
              {JSON.stringify(missingCheckbox[0])}
              <FormControlLabel
                style={{
                  paddingTop: 20,
                  color: missingCheckbox?.first === false ? '#f7c300' : undefined,
                }}
                control={
                  <Checkbox
                    onChange={handleChange}
                    name="first"
                    checked={checkboxes.first}
                    color={'primary'}
                  />
                }
                label={
                  <Typography>
                    By checking this box, you confirm that you are legally authorized to accept our{' '}
                    <Link color={'primary'} variant={'text'} target={'_blank'} href={policyFileUrl}>
                      Terms and Conditions
                    </Link>
                    .
                  </Typography>
                }
              />
              <FormControlLabel
                style={{
                  paddingTop: 20,
                  color: missingCheckbox?.second === false ? '#f7c300' : undefined,
                }}
                control={
                  <Checkbox
                    onChange={handleChange}
                    name="second"
                    checked={checkboxes.second}
                    color={'primary'}
                  />
                }
                label={
                  <Typography>
                    By checking this box, you confirm that you have read and agree to our{' '}
                    <Link color={'primary'} variant={'text'} target={'_blank'} href={policyFileUrl}>
                      Terms and Conditions
                    </Link>
                    .
                  </Typography>
                }
              />
              <FormControlLabel
                style={{
                  paddingTop: 20,
                  color: missingCheckbox?.third === false ? '#f7c300' : undefined,
                }}
                control={
                  <Checkbox
                    onChange={handleChange}
                    name="third"
                    checked={checkboxes.third}
                    color={'primary'}
                  />
                }
                label={
                  <Typography>
                    By checking this box, you confirm that you have read and agree to our{' '}
                    <Link
                      color={'primary'}
                      variant={'text'}
                      target={'_blank'}
                      href={'https://setupad.com/privacy-policy/'}
                    >
                      Privacy Policy
                    </Link>
                    .
                  </Typography>
                }
              />
            </FormGroup>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color={'primary'}
            variant={'contained'}
            onClick={acceptTOS}
            style={{margin: '0 auto'}}
          >
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PolicyDialog;
