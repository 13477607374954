import {useEffect, useCallback, useState} from 'react';
import useStore from '../store/store';
import {APICall} from '../api/api';
import usePublishers from './usePublishers';

const getStatusMessageByJobStatus = status => {
  switch (status) {
    case 'queue':
      return 'Order generation is in queue...';
    case 'in progress':
      return 'We are currently preparing your Ad manager';
    case 'success':
      return 'Your GAM account has been initialized!';
    case 'error':
      return 'Error at initialization. Please try again or contact support.';
    default:
      return 'Loading initialization status...';
  }
};

const useOrderGeneratorStatus = publisher => {
  const auth = useStore(state => state.auth);
  const setState = useStore(state => state.setState);
  const [orderGeneratorError, setError] = useState(null);
  const jobStatus = useStore(state => state.jobStatus);
  const orderGeneratorStatusLoading = useStore(state => state.orderGeneratorStatusLoading);
  const {getPublishers} = usePublishers();

  const getJobStatus = useCallback(
    async (offset = 0) => {
      setError(null);
      setState('orderGeneratorStatusLoading', true);

      let orderStatus = await APICall({
        endpoint: 'getOrderStatus',
        params: `${publisher.publisherId}/${publisher.order_generator_job_id}/${offset}`,
        options: {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        },
      });

      if (
        orderStatus.code === 'BAD_REQUEST' &&
        orderStatus.message === 'Invalid task id argument'
      ) {
        // Order generator job id is invalid, stop query, show error
        setError('Failed to load order generator status. Please try again or contact support.');
        return;
      }

      if (!orderStatus.success) {
        // On order status error, check if message doesn't say that request timed out, if so, retry.
        if (orderStatus.error?.includes('api-gam-order-generator.setupad.io/api/orderStatus')) {
          await new Promise(resolve => setTimeout(resolve, 2000));
          getJobStatus(orderStatus.logIndex);
        } else {
          // Dint time out, show error
          setError(orderStatus.error);
        }
      } else {
        if (orderStatus?.logEntries?.value !== 'No new data...') {
          setState('jobStatus', {
            ...orderStatus,
            statusMessage: getStatusMessageByJobStatus(orderStatus.taskStatus),
            lastEntry: orderStatus.logEntries
              ? orderStatus?.logEntries[orderStatus?.logEntries?.length - 1]
              : ``,
          });
        } else {
          // Skip
          setState('jobStatus', {
            ...useStore.getState().jobStatus,
            taskProgress: orderStatus.taskProgress,
            statusMessage: getStatusMessageByJobStatus(orderStatus.taskStatus),
          });
        }

        if (orderStatus.taskStatus !== 'success' && orderStatus.taskStatus !== 'error') {
          await new Promise(resolve => setTimeout(resolve, 2000));
          getJobStatus(orderStatus.logIndex);
        } else {
          await new Promise(resolve => setTimeout(resolve, 2000));
          getPublishers();
        }
      }
    },
    [auth, setState, publisher, getPublishers]
  );

  useEffect(() => {
    if (
      publisher?.order_generator_job_id &&
      publisher.gam_initialized !== 1 &&
      !orderGeneratorStatusLoading
    ) {
      getJobStatus();
    }
  }, [getJobStatus, publisher, orderGeneratorStatusLoading]);

  return {orderGeneratorError, jobStatus, orderGeneratorStatusLoading};
};

export default useOrderGeneratorStatus;
