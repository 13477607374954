import React, {useEffect, useRef} from 'react';
import Select from 'react-select';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles, useTheme} from '@material-ui/core/styles/index';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import breakpoints from '../helpers/breakpoints';
import {useMediaQuery} from 'react-responsive';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import {APICall} from '../../api/api';
import millify from 'millify';
import EuroIcon from '@material-ui/icons/Euro';
import Trend from './Trend';
import {FixedSizeList as List} from 'react-window';
import AnalyticsIcon from './../../components/img/ic_analytics.svg';
import ReactGA from 'react-ga4';
import {useParams, useHistory} from 'react-router-dom';
import useStore from '../../store/store';
import usePersistentStore from '../../store/persistentStore';
import {ExpandLess} from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import Skeleton from '@material-ui/lab/Skeleton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Link from '@material-ui/core/Link/index';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import DataTypeSelector from './DataTypeSelector';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  buttonWrapper: {
    marginBottom: 20,
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      // marginLeft: 8,
    },
  },
  icon: {
    marginRight: 4,
    left: -2,
    top: 4,
    position: 'relative',
  },
  button: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  paperMinWidth: {
    minWidth: 60,
    padding: '6px 6px',
    //marginBottom: theme.spacing(2),
  },
  customGridItem: {
    flexGrow: 1,
    textAlign: 'center',
  },
  domainImage: {
    height: 20,
    width: 20,
    position: 'relative',
    top: 5,
    backgroundColor: 'white',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '80%',
    borderRadius: 4,
    display: 'inline-block',
    marginRight: 8,
  },
}));

const checkAndmillify = number => {
  switch (number.toString()) {
    case 'NaN':
      return 0;
    case 'Infinity':
      return 0;
    default:
      return millify(number);
  }
};

const AccountsPropertiesSelection = React.memo(props => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery({query: breakpoints.mobile});
  const isTablet = useMediaQuery({query: breakpoints.tablet});
  const [selectionMenuOpen, setSelectionMenuOpen] = React.useState(false);
  const [revenue, setRevenue] = React.useState(null);
  const [searchInputValue, setSearchInputValue] = React.useState('');
  const [options, setOptions] = React.useState(props.options);
  const [successFormulaDomainData, setSuccessFormulaDomainData] = React.useState({});
  const abortController = new AbortController();
  const history = useHistory();
  const period = useStore(state => state.period);
  const propertiesSelectionScrollOffset = useStore(state => state.propertiesSelectionScrollOffset);
  const listRef = useRef();
  const auth = useStore(state => state.auth);
  const comparisonPeriod = useStore(state => state.comparisonPeriod);
  const selection = props.successFormula ? props.selection : useStore(state => state.selection);
  let {publisher, domain, placement} = useParams();
  const assignedGoogleProperties = useStore(state => state.assignedGoogleProperties);
  const dataType = useStore(state => state.dataType);
  const disableTypingForAccountSelector = useStore(state => state.disableTypingForAccountSelector);
  const collapsedDomains = useStore(state => state.collapsedDomains);
  const setState = useStore(state => state.setState);
  const enabledMetrics = props.successFormula
    ? null
    : usePersistentStore(state => state.enabledMetrics);
  const changeEnabledMetric = usePersistentStore(state => state.changeEnabledMetric);

  const isSelfServe = auth?.type >= 5 && auth?.type < 8;

  const handleSelectionMenuOpen = () => {
    let currentOffset = listRef?.current?.scrollTop;
    if (currentOffset) {
      // Save current scroll offset
      setState('propertiesSelectionScrollOffset', currentOffset);
    }
    setSelectionMenuOpen(!selectionMenuOpen);
  };

  useEffect(
    () => () => {
      // Reset scroll offset on dismount
      setState('propertiesSelectionScrollOffset', 0);
    },
    []
  );

  //  Handle keyboard. On typing open accounts properties selection if its not opened yet, on esc - close it
  useEffect(() => {
    const handleTyping = event => {
      if (
        !event.metaKey &&
        !selectionMenuOpen &&
        String.fromCharCode(event.keyCode).match(/(\w|\s)/g) &&
        event.keyCode !== 13
      ) {
        setSelectionMenuOpen(true);
      } else if (event.keyCode === 27 && selectionMenuOpen) {
        setSelectionMenuOpen(false);
      }
    };

    // Dont react to typing if typing is disabled (by other components where typing is required, like feedback form)
    if (!disableTypingForAccountSelector) {
      window.addEventListener('keydown', handleTyping);
    } else {
      window.removeEventListener('keydown', handleTyping);
    }

    return () => {
      window.removeEventListener('keydown', handleTyping);
    };
  }, [selectionMenuOpen, disableTypingForAccountSelector]);

  useEffect(() => {
    setState('selection', {
      value: {
        publisher,
        domain,
        placement,
      },
    });
  }, [publisher, domain, placement]);

  useEffect(() => {
    setState('selectionLabel', getValueByProps());
  }, [props]);

  const onSelectionChange = change => {
    ReactGA.event({
      category: 'placements dropdown picker',
      action: 'click',
      label: change.value.placement ? 'placement' : change.value.domain ? 'domain' : 'total',
    });

    history.push(
      `/home${change.value.publisher ? `/${change.value.publisher}` : ``}${
        change.value.domain ? `/${change.value.domain}` : ``
      }${change.value.placement ? `/${change.value.placement}` : ``}`
    );

    setState('calculated', null);

    setState('selection', change);
  };

  useEffect(() => {
    if (!isMobile && !props.disableRevenueInDropdown) {
      getRevenue();
    }

    // Get data for every domain to display in dropdown for success formula
    // Works only if there are less than 10 domains in list to save GA quota
    if (props.successFormula && props.options.length < 15) {
      setSuccessFormulaDomainData({});
      getDataForSuccessFormula(period);
    }

    return () => abortController.abort();
  }, [period, auth, dataType]);

  useEffect(() => {
    if (revenue) {
      sortFormatOptions();
    }
  }, [revenue, collapsedDomains]);

  const getDataForSuccessFormula = async subscribedAtPeriod => {
    for (const option of options) {
      // Check if period prop didnt change
      if (JSON.stringify(subscribedAtPeriod) === JSON.stringify(period)) {
        const domainStats = await props.getPublisherStats(option, true);

        setSuccessFormulaDomainData(prevData => {
          // console.log('domainStats prevData', prevData);
          // console.log('domainStats', domainStats);
          return {...prevData, [option.value.domain]: domainStats};
        });
      }
    }
  };

  // Function to add revenue data for menu options.
  const sortFormatOptions = () => {
    let formattedOptions = props.options.map(option => {
      let adUnitStats;
      let adUnitStatsComparison;

      // If inventory, calculate totals
      if (option.label === 'Inventory') {
        adUnitStats = {
          revenue: revenue.forPeriod.reduce((sum, adUnit) => parseFloat(adUnit.revenue) + sum, 0),
          requests: revenue.forPeriod.reduce((sum, adUnit) => parseInt(adUnit.requests) + sum, 0),
          viewability:
            revenue.forPeriod.reduce(
              (sum, adUnit) => parseFloat(adUnit.viewability) * parseInt(adUnit.impressions) + sum,
              0
            ) / revenue.forPeriod.reduce((sum, adUnit) => parseInt(adUnit.impressions) + sum, 0),
          ecpm:
            revenue.forPeriod.reduce(
              (sum, adUnit) => parseFloat(adUnit.ecpm) * parseInt(adUnit.impressions) + sum,
              0
            ) / revenue.forPeriod.reduce((sum, adUnit) => parseInt(adUnit.impressions) + sum, 0),
        };

        adUnitStatsComparison = {
          revenue: revenue.forComparison.reduce(
            (sum, adUnit) => parseFloat(adUnit.revenue) + sum,
            0
          ),
          requests: revenue.forComparison.reduce(
            (sum, adUnit) => parseInt(adUnit.requests) + sum,
            0
          ),
          viewability:
            revenue.forComparison.reduce(
              (sum, adUnit) => parseFloat(adUnit.viewability) * parseInt(adUnit.impressions) + sum,
              0
            ) /
            revenue.forComparison.reduce((sum, adUnit) => parseInt(adUnit.impressions) + sum, 0),
          ecpm:
            revenue.forComparison.reduce(
              (sum, adUnit) => parseFloat(adUnit.ecpm) * parseInt(adUnit.impressions) + sum,
              0
            ) /
            revenue.forComparison.reduce((sum, adUnit) => parseInt(adUnit.impressions) + sum, 0),
        };
      }
      // If domain, calculate total domain
      else if (option.label?.includes('domain')) {
        let domainDataForPeriod = revenue.forPeriod.filter(adUnit =>
          option.adUnits?.includes(adUnit.id)
        );
        let domainDataForComparison = revenue.forComparison.filter(adUnit =>
          option.adUnits?.includes(adUnit.id)
        );

        adUnitStats = {
          revenue: domainDataForPeriod.reduce((sum, adUnit) => parseFloat(adUnit.revenue) + sum, 0),
          requests: domainDataForPeriod.reduce((sum, adUnit) => parseInt(adUnit.requests) + sum, 0),
          viewability:
            domainDataForPeriod.reduce(
              (sum, adUnit) => parseFloat(adUnit.viewability) * parseInt(adUnit.impressions) + sum,
              0
            ) / domainDataForPeriod.reduce((sum, adUnit) => parseInt(adUnit.impressions) + sum, 0),
          ecpm:
            domainDataForPeriod.reduce(
              (sum, adUnit) => parseFloat(adUnit.ecpm) * parseInt(adUnit.impressions) + sum,
              0
            ) / domainDataForPeriod.reduce((sum, adUnit) => parseInt(adUnit.impressions) + sum, 0),
        };

        adUnitStatsComparison = {
          revenue: domainDataForComparison.reduce(
            (sum, adUnit) => parseFloat(adUnit.revenue) + sum,
            0
          ),
          requests: domainDataForComparison.reduce(
            (sum, adUnit) => parseInt(adUnit.requests) + sum,
            0
          ),
          viewability:
            domainDataForComparison.reduce(
              (sum, adUnit) => parseFloat(adUnit.viewability) * parseInt(adUnit.impressions) + sum,
              0
            ) /
            domainDataForComparison.reduce((sum, adUnit) => parseInt(adUnit.impressions) + sum, 0),
          ecpm:
            domainDataForComparison.reduce(
              (sum, adUnit) => parseFloat(adUnit.ecpm) * parseInt(adUnit.impressions) + sum,
              0
            ) /
            domainDataForComparison.reduce((sum, adUnit) => parseInt(adUnit.impressions) + sum, 0),
        };
      }

      // If ad unit, calculate for ad unit
      else {
        adUnitStats = revenue.forPeriod
          ? revenue.forPeriod.filter(adUnit => adUnit.id === option.value.placement)[0]
          : null;

        adUnitStatsComparison = revenue.forComparison
          ? revenue.forComparison.filter(adUnit => adUnit.id === option.value.placement)[0]
          : null;
      }

      return {
        ...option,
        rev: adUnitStats ? parseFloat(adUnitStats.revenue) : !option.value.placement ? 0 : 0,
        requests: adUnitStats ? parseFloat(adUnitStats.requests) : 0,
        revComparison: adUnitStatsComparison ? parseFloat(adUnitStatsComparison.revenue) : 0,
        requestsComparison: adUnitStatsComparison ? parseFloat(adUnitStatsComparison.requests) : 0,
        viewability: adUnitStats?.viewability ? parseFloat(adUnitStats.viewability).toFixed(2) : 0,
        viewabilityComparison: adUnitStatsComparison?.viewability
          ? parseFloat(adUnitStatsComparison.viewability).toFixed(2)
          : 0,
        eCPM: parseFloat(adUnitStats?.ecpm)?.toFixed(2),
        eCPMComparison: parseFloat(adUnitStatsComparison?.ecpm)?.toFixed(2),
        adUnitStopped: adUnitStats
          ? adUnitStats.lastDate !== period.endDate
            ? adUnitStats.lastDate
              ? `Inactive after: ${adUnitStats.lastDate}`
              : false
            : false
          : `Inactive on selected period`,
      };
    });

    formattedOptions.sort((a, b) =>
      parseInt(a.value.domain) === parseInt(b.value.domain)
        ? b.rev - a.rev
        : parseInt(b.value.domain) - parseInt(a.value.domain)
    );

    setOptions(formattedOptions);
  };

  const getRouteByDataType = () => {
    switch (dataType) {
      case 'setupad':
        return 'clientperiodsetupad';
      case 'combined':
        return 'clientperiodown';
      case 'publisher':
        return 'clientperiodpublisher';
    }
  };

  const getRevenue = async () => {
    try {
      setRevenue(null);

      let endpoint =
        dataType === 'setupad'
          ? 'clientperiodsetupad'
          : [4, 5, 6, 7].includes(props.login.type)
          ? getRouteByDataType()
          : 'clientperiod';

      let query = await APICall({
        endpoint,
        params: `${period.startDate}/${period.endDate}`,
        options: {
          signal: abortController.signal,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${props.login.reportingToken}`,
            'Cache-Control': 'max-age=0, no-cache',
          },
        },
      });

      let comparisonRevenue = await APICall({
        endpoint,
        params: `${comparisonPeriod.startDate}/${comparisonPeriod.endDate}`,
        options: {
          signal: abortController.signal,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${props.login.reportingToken}`,
            'Cache-Control': 'max-age=0, no-cache',
          },
        },
      });

      setRevenue({
        forPeriod: query,
        forComparison: comparisonRevenue,
      });
    } catch (e) {}
  };

  const getValueByProps = () => {
    let value;

    if (!selection) {
      return 'Select domain';
    }

    if (!selection.value.publisher) {
      value = props.options[0];
    }

    if (selection.value.domain) {
      value = props.options.find(option => option.value.domain === selection.value.domain);
    }

    if (selection.value.placement) {
      value = props.options.find(option => option.value.placement === selection.value.placement);
    }

    // setState('selectionLabel', value.label);

    if (value && value.value) {
      return value.label;
    } else {
      window.location.replace(props.successFormula ? '/success-formula' : '/home');
    }
  };

  const Menu = props => {
    const shadow = 'rgba(0,0,0,0.3)';
    return (
      <Paper
        style={{
          //   backgroundColor: 'white',
          borderRadius: 4,
          boxShadow: `30px 0 100px ${shadow}`,
          marginTop: 4,
          position: 'absolute',
          width: '100%',
          zIndex: 2,
          minWidth: 'calc(100vw - 350px)',
        }}
        {...props}
      />
    );
  };
  const Blanket = props => (
    <div
      css={{
        bottom: 0,
        left: 0,
        top: 0,
        right: 0,
        position: 'fixed',
        zIndex: 1,
        background: 'red',
      }}
      {...props}
    />
  );
  const Dropdown = ({children, isOpen, target, onClose}) => (
    <div style={{position: 'relative'}}>
      {target}

      <div
        style={{
          position: 'fixed',
          // background: 'rgba(0,0,0,0.7)',
          background: theme.palette.background.paper,
          opacity: 0.5,
          left: 0,
          top: 0,
          zIndex: 2,
          width: '100%',
          height: '100%',
          display: selectionMenuOpen && !isMobile ? 'block' : 'none',
        }}
      />

      <>
        {isOpen ? <Menu>{children}</Menu> : null}
        {isOpen ? <Blanket onClick={onClose} /> : null}
      </>
    </div>
  );

  const Svg = p => (
    <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation" {...p} />
  );
  const DropdownIndicator = () => (
    <div css={{color: 'white'}}>
      <Svg>
        <path
          d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </Svg>
    </div>
  );

  const MenuList = ({options, children, maxHeight, getValue}) => {
    const height = props.successFormula ? 120 : 47;
    const [value] = getValue();

    const domainsIds = props.options
      .filter(option => option.value.domain && !option.value.placement)
      .map(filtered => filtered.value.domain);
    return (
      <>
        {!isMobile && !props.successFormula && (
          <div style={{textAlign: 'right', paddingRight: 10, boxShadow: '0 3px 5px #0000002e'}}>
            {props.login.daily_setupad && <DataTypeSelector inDropdown={true} />}
            {Object.keys(enabledMetrics).map(metric => (
              <>
                <Typography display={'inline'} variant={'caption'}>
                  Show {metric}
                </Typography>
                <Switch
                  checked={enabledMetrics[metric]}
                  color={'primary'}
                  onChange={e => {
                    e.preventDefault();
                    e.stopPropagation();

                    changeEnabledMetric(metric, e.target.checked);

                    ReactGA.event({
                      category: `show ${metric} in dropdown`,
                      action: 'click',
                      label: e.target.checked ? 'enable' : 'disable',
                    });
                  }}
                />
              </>
            ))}

            <Typography display={'inline'} variant={'caption'}>
              Collapse all domains
            </Typography>
            <Switch
              checked={domainsIds.length === collapsedDomains.length}
              color={'primary'}
              onChange={e => {
                e.preventDefault();
                e.stopPropagation();
                setState('collapsedDomains', e.target.checked ? domainsIds : []);
                // Reset offset on collapse / expand
                setState('propertiesSelectionScrollOffset', 0);
                ReactGA.event({
                  category: 'collapse all domains',
                  action: 'click',
                  label: e.target.checked ? 'enable' : 'disable',
                });
              }}
            />
          </div>
        )}
        <List
          height={Math.min(window.innerHeight * 0.7, height * children.length)}
          itemCount={children.length}
          itemSize={height}
          initialScrollOffset={propertiesSelectionScrollOffset}
          outerRef={listRef}
        >
          {({index, style}) => <div style={style}>{children[index]}</div>}
        </List>
      </>
    );
  };

  const selectStyles = {
    container: provided => ({...provided, minWidth: '100%', marginTop: 0}),
    input: provided => ({...provided, color: 'inherit', fontSize: 16}),
    control: provided => ({...provided, minWidth: '100%', marginTop: 0, background: 'inherit'}),
    menu: () => ({boxShadow: '0 1px 20px rgba(0, 0, 0, .1)', position: 'relative'}),
    menuList: provided => ({...provided, maxHeight: isMobile ? '75vh' : '60vh'}),
    option: (provided, state) => {
      //console.log(provided)
      return {
        marginTop: isMobile ? 6 : undefined,
        ...provided,
        cursor: 'pointer',
        color: 'inherit',
        opacity: state.isFocused ? 1 : 0.8,
        backgroundColor: state.isFocused ? 'rgba(199, 199, 199, .05)' : 'none',
        //backgroundColor: 'none',

        '&:active': {
          background: 'none',
          opacity: 1,
        },
        '&:hover': {
          //backgroundColor: 'none',
          opacity: 1,
        },
      };
    },
  };

  const formatOptionLabel = ({
    value,
    label,
    customAdditionalLabel,
    rev,
    requests,
    revComparison,
    requestsComparison,
    assignedProperty,
    viewability,
    viewabilityComparison,
    eCPM,
    eCPMComparison,
    adUnitStopped,
  }) => {
    return (
      <Grid
        container
        spacing={2}
        style={props.successFormula ? {paddingTop: 10, paddingLeft: 10, paddingRight: 10} : {}}
      >
        <Grid
          item
          xs={revenue ? 12 : props.successFormula ? 4 : 12}
          style={
            label.includes('domain')
              ? {
                  borderTop: `1px solid ${theme.palette.graphColors.themeLightGrayOpaque}`,
                }
              : {}
          }
        >
          {!props.successFormula && revenue && adUnitStopped && (
            <Tooltip arrow title={adUnitStopped} interactive>
              <ReportProblemIcon
                style={{color: 'red', top: 6, marginRight: 8, position: 'relative', fontSize: '20'}}
              />
            </Tooltip>
          )}

          {customAdditionalLabel ? (
            <div
              style={{
                marginLeft: revenue && adUnitStopped ? 0 : 25,
                marginRight: 10,
                color: '#ccc',
                position: 'relative',
                top: '-0px',
                display: 'inline-block',
              }}
            >
              <Chip size={'small'} label={customAdditionalLabel} />
            </div>
          ) : (
            ''
          )}

          {label.includes('domain') || props.successFormula ? (
            <>
              {/* <Computer className={classes.icon} /> */}
              <div
                className={classes.domainImage}
                style={{
                  backgroundImage: `url(https://www.google.com/s2/favicons?domain=https://${label.replace(
                    ' domain',
                    ''
                  )}&sz=256)`,
                }}
              />
            </>
          ) : label.includes('Inventory') ? (
            <AccountBoxIcon className={classes.icon} />
          ) : (
            ''
          )}

          {!isMobile && label.includes('domain') && !props.successFormula && (
            <div
              style={{zIndex: 99999, display: 'inline', float: 'right'}}
              onClick={e => e.stopPropagation()}
            >
              <IconButton
                //style={{border: `1px solid ${theme.palette.graphColors.themeLightGrayOpaque}`}}
                size={'small'}
                onClick={() => {
                  ReactGA.event({
                    category: 'dropdown hide domain ad units',
                    action: 'click',
                    label: label,
                  });
                  setState(
                    'collapsedDomains',
                    collapsedDomains?.includes(value.domain)
                      ? collapsedDomains.filter(collapsed => collapsed !== value.domain)
                      : [...collapsedDomains, value.domain]
                  );
                }}
              >
                {collapsedDomains && collapsedDomains?.includes(value.domain) ? (
                  <ExpandLess />
                ) : (
                  <ExpandMoreIcon />
                )}
              </IconButton>
            </div>
          )}

          <Typography
            variant={props.successFormula ? 'h6' : 'body'}
            display={'inline'}
            style={
              label.includes('domain') || label.includes('Inventory')
                ? {
                    fontWeight: 'bold',
                  }
                : {}
            }
          >
            {customAdditionalLabel ? label.replace(customAdditionalLabel, '') : label}

            {!props.successFormula &&
              label.includes('domain') &&
              assignedGoogleProperties?.find(p => p.domainId === value.domain) && (
                <Tooltip
                  arrow
                  title={`Analytics connected: ${JSON.stringify(
                    assignedGoogleProperties?.find(p => p.domainId === value.domain)?.property
                      ?.label
                  )}`}
                >
                  <img
                    src={AnalyticsIcon}
                    style={{position: 'relative', top: 2, height: 18, width: 22, paddingLeft: 5}}
                    alt={label}
                  />
                </Tooltip>
              )}
          </Typography>

          {assignedProperty && assignedProperty.label && (
            <Typography variant={'body'} display={'block'}>
              {assignedProperty?.label}
            </Typography>
          )}

          {!revenue &&
            !isMobile &&
            !props.successFormula &&
            Object.keys(enabledMetrics)?.find(metric => enabledMetrics[metric])?.length > 0 &&
            Object.keys(enabledMetrics)
              .filter(metric => enabledMetrics[metric] === true)
              .map(() => (
                <Skeleton
                  width={'8%'}
                  height={26}
                  style={{
                    display: 'inline-block',
                    position: 'relative',
                    top: -4,
                    marginLeft: 10,
                  }}
                />
              ))}

          {revenue && rev > 0 && (
            <div
              style={{
                marginLeft: 10,
                position: 'relative',
                top: '-2px',
                display: 'inline-block',
              }}
            >
              {enabledMetrics.revenue && (
                <>
                  <Chip
                    style={{
                      border: `1px solid ${theme.palette.graphColors.themeLightGrayOpaque}`,
                    }}
                    variant="outlined"
                    size={'small'}
                    deleteIcon={<EuroIcon />}
                    onDelete={() => {}}
                    label={
                      <>
                        <Typography
                          variant={'body'}
                          style={{fontWeight: 600}}
                          color={'textSecondary'}
                        >
                          {`${millify(rev)}`}
                        </Typography>
                      </>
                    }
                    color="secondary"
                  />

                  <Trend
                    inDropdown={true}
                    enabled={props.comparisonEnabled}
                    current={rev}
                    previous={revComparison}
                  />
                </>
              )}

              {enabledMetrics.requests && (
                <>
                  <Chip
                    style={{
                      border: `1px solid ${theme.palette.graphColors.themeLightGrayOpaque}`,
                      marginLeft: 5,
                    }}
                    variant="outlined"
                    size={'small'}
                    color={'inherit'}
                    label={
                      <Typography
                        variant={'body'}
                        style={{fontWeight: 600}}
                        color={'textSecondary'}
                      >
                        {`${millify(requests)} requests`}
                      </Typography>
                    }
                  />

                  <Trend
                    inDropdown={true}
                    enabled={props.comparisonEnabled}
                    current={requests}
                    previous={requestsComparison}
                  />
                </>
              )}

              {enabledMetrics.viewability && !isSelfServe && (
                <>
                  <Tooltip
                    arrow
                    interactive
                    open={viewability > 70 ? false : undefined}
                    title={
                      <React.Fragment>
                        {viewability < 50
                          ? `Below 50% is too low and means that need to focus on your ad viewability. Contact your account executive for ideas how viewability may be improved.`
                          : `50%-70% is acceptable, but for optimal results you need to try and reach above 70%.`}
                        <br />
                        <Link
                          href="https://setupad.com/blog/ad-viewability/"
                          target={'_blank'}
                          color="primary"
                        >
                          Read more
                        </Link>
                      </React.Fragment>
                    }
                  >
                    <Chip
                      style={{
                        border:
                          viewability < 50
                            ? `1px solid red`
                            : viewability < 70
                            ? `1px solid ${theme.palette.graphColors.viewability}`
                            : ``,
                        marginLeft: 5,
                      }}
                      variant="outlined"
                      size={'small'}
                      deleteIcon={
                        viewability < 50 ? (
                          <VisibilityOffIcon style={{color: 'red'}} />
                        ) : (
                          <VisibilityIcon
                            style={
                              viewability < 70 ? {color: theme.palette.graphColors.viewability} : {}
                            }
                          />
                        )
                      }
                      onDelete={() => {}}
                      label={
                        <Typography
                          variant={'body'}
                          style={{fontWeight: 600}}
                          color={'textSecondary'}
                        >
                          {`${viewability}%`}
                        </Typography>
                      }
                    />
                  </Tooltip>

                  <Trend
                    inDropdown={true}
                    enabled={props.comparisonEnabled}
                    current={viewability}
                    previous={viewabilityComparison}
                  />
                </>
              )}

              {enabledMetrics.ecpm && (
                <>
                  <Chip
                    style={{
                      marginLeft: 5,
                    }}
                    variant="outlined"
                    size={'small'}
                    deleteIcon={<EuroIcon style={{color: theme.palette.graphColors.eCPM}} />}
                    onDelete={() => {}}
                    label={
                      <Typography
                        variant={'body'}
                        style={{fontWeight: 600}}
                        color={'textSecondary'}
                      >
                        {`${eCPM}`}
                      </Typography>
                    }
                  />

                  <Trend
                    inDropdown={true}
                    enabled={props.comparisonEnabled}
                    current={eCPM}
                    previous={eCPMComparison}
                  />
                </>
              )}
            </div>
          )}
        </Grid>

        {props.successFormula &&
          successFormulaDomainData.hasOwnProperty(value.domain) &&
          successFormulaDomainData[value.domain]?.success &&
          successFormulaDomainData[value.domain]?.data && (
            <Grid item xs={8}>
              <Grid container spacing={1} justify="center">
                <Grid item className={classes.customGridItem}>
                  <Paper elevation={1} className={classes.paperMinWidth}>
                    <Typography variant={'subtitle'}>
                      <img src={AnalyticsIcon} style={{paddingRight: 10, height: 14}} />
                      Users
                    </Typography>
                    <Typography variant={'h6'}>
                      {checkAndmillify(successFormulaDomainData[value.domain].data.users)}
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item className={classes.customGridItem}>
                  <Paper elevation={1} className={classes.paperMinWidth}>
                    <Typography variant={'subtitle'}>
                      <img src={AnalyticsIcon} style={{paddingRight: 10, height: 14}} />
                      Loyalty
                    </Typography>
                    <Typography variant={'h6'}>
                      {checkAndmillify(successFormulaDomainData[value.domain].data.sessionsPerUser)}
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item className={classes.customGridItem}>
                  <Paper elevation={1} className={classes.paperMinWidth}>
                    <Typography variant={'subtitle'}>
                      <img src={AnalyticsIcon} style={{paddingRight: 10, height: 14}} />
                      Engagement
                    </Typography>
                    <Typography variant={'h6'}>
                      {checkAndmillify(
                        successFormulaDomainData[value.domain].data.pageviewsPerSession
                      )}
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item className={classes.customGridItem}>
                  <Paper elevation={1} className={classes.paperMinWidth}>
                    <Typography variant={'subtitle'}>
                      <img
                        src={'https://setupad.com/wp-content/uploads/2020/04/favicon-icon-192.png'}
                        style={{paddingRight: 10, height: 14}}
                      />
                      Ads per page
                    </Typography>
                    <Typography variant={'h6'}>
                      {successFormulaDomainData[value.domain].data.ads}
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item className={classes.customGridItem}>
                  <Paper elevation={1} className={classes.paperMinWidth}>
                    <Typography variant={'subtitle'}>
                      <img
                        src={'https://setupad.com/wp-content/uploads/2020/04/favicon-icon-192.png'}
                        style={{paddingRight: 10, height: 14}}
                      />
                      eCPM
                    </Typography>
                    <Typography variant={'h6'}>
                      {checkAndmillify(successFormulaDomainData[value.domain].data.eCPM)}
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item className={classes.customGridItem}>
                  <Paper elevation={1} className={classes.paperMinWidth}>
                    <Typography variant={'subtitle'}>
                      <img
                        src={'https://setupad.com/wp-content/uploads/2020/04/favicon-icon-192.png'}
                        style={{paddingRight: 10, height: 14}}
                      />
                      Revenue
                    </Typography>
                    <Typography variant={'h6'}>
                      {checkAndmillify(successFormulaDomainData[value.domain].data.revenue)}€
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          )}

        {props.successFormula &&
          !successFormulaDomainData.hasOwnProperty(value.domain) &&
          props.options?.length < 15 && <CircularProgress color={'primary'} size={20} />}
      </Grid>
    );
  };

  return (
    <div className={classes.buttonWrapper}>
      <Dropdown
        isOpen={selectionMenuOpen}
        onClose={() => handleSelectionMenuOpen()}
        target={
          <Button
            className={classes.button}
            color={'primary'}
            variant={'contained'}
            size={'big'}
            //fullWidth={!!props.fullWidth}
            endIcon={
              revenue || isMobile || props.disableRevenueInDropdown ? (
                selectionMenuOpen ? (
                  <ExpandLess />
                ) : (
                  <ExpandMoreIcon />
                )
              ) : (
                <Tooltip title={'Loading statistics'}>
                  <CircularProgress color={'inherit'} size={20} />
                </Tooltip>
              )
            }
            onClick={() => handleSelectionMenuOpen()}
            isSelected={selectionMenuOpen}
          >
            {getValueByProps()}
          </Button>
        }
      >
        <Select
          value={{
            label: selection.label,
            value: selection.value,
          }}
          autoFocus
          noOptionsMessage={() => <Typography>Nothing found</Typography>}
          onBlur={() => handleSelectionMenuOpen()}
          formatOptionLabel={formatOptionLabel}
          backspaceRemovesValue={false}
          styles={selectStyles}
          tabSelectsValue={false}
          menuIsOpen
          isClearable={false}
          components={{DropdownIndicator, IndicatorSeparator: null, MenuList}}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          placeholder={'Type to search'}
          inputValue={searchInputValue}
          onInputChange={change => setSearchInputValue(change)}
          options={options}
          onChange={change => {
            handleSelectionMenuOpen();
            if (props.successFormula) {
              props.onSelectionChange(change);
            } else {
              onSelectionChange(change);
            }
          }}
        />
      </Dropdown>
    </div>
  );
});

export default React.memo(AccountsPropertiesSelection);
